import { FC } from 'react'
import { upperFirst } from 'lodash'
import {
  Checkbox,
  FormattedDate,
  FormattedMoney,
  FormattedNumber,
  FormattedPercent,
  FormattedQuantity,
  PickerInput,
} from '@common/components'
import { Table, TD, TH, TR } from '../../../../../components'
import {
  OpportunitySaleStatus,
  OpportunityUnit,
  useUpdateOpportunityUnitStatusMutation,
} from '../../../../../graphql'

type Props = {
  currency: string
  units?: OpportunityUnit[]
}

export const UnitsTable: FC<Props> = ({ currency, units = [] }) => {
  const [updateOpportunity] = useUpdateOpportunityUnitStatusMutation({
    ignoreResults: true,
  })

  return (
    <Table>
      <TR bg="light1">
        <TH size={1}>Unit no</TH>
        <TH size={1}>Unit type</TH>
        <TH size={1}>Floor</TH>
        <TH size={1}>Size</TH>
        <TH size={1}>Price</TH>
        <TH size={1}>Rent exp.</TH>
        <TH size={1}>Rent</TH>
        <TH size={1}>Levies</TH>
        <TH size={1}>Taxes</TH>
        <TH size={1}>Cash flow</TH>
        <TH size={1}>Gross Yield</TH>
        <TH size={1}>Net Yield</TH>
        <TH size={1}>IRR</TH>
        <TH size={1}>TMB</TH>
        <TH size={1} textAlign="center">
          Published
        </TH>
        <TH size={1}>Status</TH>
        <TH size={2}>Client</TH>
      </TR>

      {units.map((unit, index) => (
        <TR key={unit.id} index={index}>
          <TD size={1} py="xxsmall">
            {unit.unitNumber}
          </TD>
          <TD size={1} py="xxsmall">
            {unit.unitType}
          </TD>
          <TD size={1} py="xxsmall">
            {unit.floor}
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedQuantity value={unit.floorSize} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedMoney amount={unit.purchasePrice} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedDate value={unit.rentExpires} month="numeric" />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedMoney amount={unit.rentAmount} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedMoney amount={unit.levies} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedMoney amount={unit.taxes} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedMoney amount={unit.monthlyCashFlow} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedPercent value={unit.grossYield} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedPercent value={unit.netYield} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            <FormattedPercent value={unit.irr} currency={currency} />
          </TD>
          <TD size={1} py="xxsmall" asText>
            x<FormattedNumber value={unit.moneyBackReturn} maximumFractionDigits={2} />
          </TD>
          <TD size={1} py="xxsmall" alignItems="center">
            <Checkbox
              size="small"
              checked={unit.published}
              onPress={() =>
                updateOpportunity({
                  variables: {
                    input: { id: unit.id, published: !unit.published },
                  },
                })
              }
            />
          </TD>
          <TD size={1} py="xxsmall">
            <PickerInput
              options={[
                { label: 'Available', value: 'available' },
                { label: 'Reserved', value: 'reserved' },
                { label: 'Sold', value: 'sold' },
              ]}
              selectedValue={{ label: upperFirst(unit?.status ?? 'available') }}
              onValueChange={(newStatus) =>
                updateOpportunity({
                  variables: {
                    input: { id: unit.id, status: newStatus.value as OpportunitySaleStatus },
                  },
                })
              }
              style={{ height: 22 }}
            />
          </TD>
          <TD size={2} py="xxsmall">
            -
          </TD>
        </TR>
      ))}
    </Table>
  )
}
