import { useField } from 'react-final-form'
import { H3 } from '@common/components'
import { FormHtml } from '../../../../forms'
import { EditorSection } from '../EditorSection'

type Props = {
  fieldName: string
}

export const RichTextContent = ({ fieldName }: Props) => {
  const { input, meta } = useField(`${fieldName}.html`)
  return (
    <EditorSection preview={<RichTextContentPreview html={input.value} />}>
      <FormHtml input={input} meta={meta} editorHeight={350} />
    </EditorSection>
  )
}

const RichTextContentPreview = ({ html }: { html: string }) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  const plainText = doc.body.textContent?.substring(0, 32) ?? ''
  return (
    <H3 mb="none" color="foreground">
      {plainText.length > 1 ? plainText.replace(/[\s]+/g, ' ') + '…' : 'Empty Text Section'}
    </H3>
  )
}
