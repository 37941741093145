import { FC } from 'react'
import { FieldMetaState, FieldRenderProps } from 'react-final-form'
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native'
import Constants from 'expo-constants'
import { PickerInput } from '@common/components'
import { useDeepLinkUrlOpportunityFormInputQuery } from '../../../../../graphql'

const { mobileAppScheme } = Constants.manifest?.extra ?? {}

interface Props {
  input: {
    name: string
    value: string
    onChange: (text: string) => void
    onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
  }
  meta: FieldMetaState<string>
}

const DeepLinkUrlInput = ({
  input,
  meta: { touched, error: validationError, submitError },
  ...props
}: Props) => {
  const { loading, data, error: queryError } = useDeepLinkUrlOpportunityFormInputQuery()

  const error = queryError || (touched && (submitError || validationError))

  const options =
    data?.opportunities.map((opportunity) => ({
      label: opportunity.name,
      value: `${mobileAppScheme}://opportunity/${opportunity.id}`,
    })) ?? []

  return (
    <PickerInput
      {...props}
      options={options}
      selectedValue={options.find((o) => o.value === input.value) ?? null}
      onValueChange={(option) => input.onChange(option?.value ?? '')}
      isLoading={loading}
      disabled={loading}
      error={error}
    />
  )
}

// react-final-form does not have native typings, this forces
// compatibility with the Field component.
type FinalFormInput = FC<FieldRenderProps<string, HTMLElement>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeepLinkOpportunityFormInput = DeepLinkUrlInput as any as FinalFormInput
