import { createContext, FC, useContext } from 'react'
import { View } from 'react-native'
import { Col, ColProps, Grid } from 'react-native-easy-grid'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  space,
  SpaceProps,
} from 'styled-system'
import { Box, BoxProps, Text } from '@common/components'
import { Color, FontSize, FontWeight, TextAlign } from '@common/theme'

type TableContextType = {
  color?: Color
  fontSize?: FontSize
  fontWeight?: FontWeight
}

const TableStyleContext = createContext<TableContextType>({})

export const Table: FC<BoxProps> = (props) => (
  <Box borderColor="border" borderWidth={1} {...props} />
)

export type GridBoxProps = View['props'] & ColorProps & SpaceProps & BorderProps
export type TableRowProps = {
  oddBg?: Color
  evenBg?: Color
  index?: number
} & GridBoxProps &
  TableContextType

export const GridBox = styled(Grid)<GridBoxProps>(color, space, border)

export const TR: FC<TableRowProps> = ({
  oddBg = 'light2',
  evenBg,
  index = 0,
  color,
  fontSize = 'small',
  fontWeight,
  ...props
}) => (
  <TableStyleContext.Provider value={{ color, fontSize, fontWeight }}>
    <GridBox bg={index % 2 === 0 ? evenBg : oddBg} {...props} />
  </TableStyleContext.Provider>
)

export type ColBoxProps = ColProps & SpaceProps & BorderProps & FlexboxProps

export const ColBox = styled(Col)<ColBoxProps>(space, border, flexbox)

const TCell: FC<ColBoxProps> = ({ ...boxProps }) => (
  <ColBox py="small" px="small" justifyContent="center" {...boxProps} />
)

type TDTextProps = {
  asText?: boolean
  textAlign?: TextAlign
  fontWeight?: FontWeight
}

export const TD: FC<ColBoxProps & TDTextProps> = ({
  asText = false,
  textAlign,
  fontWeight,
  children,
  ...props
}) => {
  const textStyle = useContext(TableStyleContext)
  return asText || typeof children !== 'object' ? (
    <TCell {...props}>
      <Text {...textStyle} textAlign={textAlign} fontWeight={fontWeight}>
        {children}
      </Text>
    </TCell>
  ) : (
    <TCell {...props}>{children}</TCell>
  )
}

export const TH: FC<ColBoxProps & TDTextProps> = (props) => (
  <TD borderColor="border" borderBottomWidth={1} fontWeight="semiBold" {...props} />
)
