import { FC } from 'react'
import { ScrollView, ScrollViewProps, ViewStyle } from 'react-native'
import { useTheme } from 'styled-components'
import { Box } from '@common/components'

interface Props extends ScrollViewProps {
  /** Padding applied on all sides of content, `small` by default */
  padding?: string
  /** A background color name */
  bg?: string
  /** Styles to apply to the outer container */
  style?: ViewStyle
  /** Styles to apply to the ScrollView content container */
  contentContainerStyle?: ViewStyle
}

export const Content: FC<Props> = ({
  padding = 'medium',
  bg = 'background',
  contentContainerStyle,
  style,
  ...props
}) => {
  const theme = useTheme()
  return (
    <Box flex={1} style={style}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          // @ts-ignore todo Why are theme typings not working here?
          padding: theme.space[padding],
          // Must provide bottom padding explicitly for Android
          // https://github.com/facebook/react-native/issues/15707#issuecomment-340759872
          // @ts-ignore todo Why are theme typings not working here?
          paddingBottom: theme.space[padding],
          ...contentContainerStyle,
        }}
        {...props}
      />
    </Box>
  )
}
