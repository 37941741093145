/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react'
import Constants from 'expo-constants'
import { NotifiableError, OnErrorCallback } from '@bugsnag/core'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { getCurrentUserAsync } from '@common/hooks'

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: Constants.manifest?.extra?.bugsnagApiKey,
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: Constants.manifest?.extra?.bugsnagReleaseState,
    autoTrackSessions: Constants.manifest?.extra?.bugsnagAutoTrackSessions,
    plugins: [new BugsnagPluginReact()],
    async onError(event) {
      try {
        const user = await getCurrentUserAsync()
        if (user) {
          event.setUser(user.uid, user.email ?? undefined, user.displayName ?? undefined)
        }
      } catch (e) {
        // Ignore errors
      }
    },
  })
} else {
  Bugsnag.notify = (error: NotifiableError, onError?: OnErrorCallback) => {
    console.error(error) // eslint-disable-line
    if (onError) {
      // @ts-ignore Not a real event, only logging metadata
      onError({ addMetadata: (name, data) => console.log(name, data) }) // eslint-disable-line
    }
  }
}

const Noop = ({ children }: { children: React.ReactElement }) => children

export const BugsnagErrorBoundary =
  process.env.NODE_ENV === 'production'
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Bugsnag.getPlugin('react')!.createErrorBoundary(React)
    : Noop
