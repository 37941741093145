import { FC } from 'react'
import { View } from 'react-native'
import styled from 'styled-components'
import { Small } from '@common/components'

const FooterSection = styled(View)`
  position: relative;
  text-align: center;
  color: ${(props) => props.theme.colors.dark5};
  font-size: ${(props) => props.theme.fontSizes.small}px;
  padding: ${(props) => props.theme.space.small}px 0;
  margin-top: ${(props) => props.theme.space.medium}px;
`

export const Footer: FC = () => (
  <FooterSection>
    <Small color="dark4">&copy; 2020 Property Clan Technologies Inc. All rights reserved</Small>
  </FooterSection>
)
