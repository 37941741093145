import { FC } from 'react'
import { Pressable } from 'react-native'
import { FormattedDate, FormattedPercent } from '@common/components'
import { divideSafe } from '@common/financial-model'
import { Table, TD, TH, TR } from '../../../../components'
import { PushCampaignListItemFragment } from '../../../../graphql'

type Props = {
  campaigns: PushCampaignListItemFragment[]
  onPress: (campaignId: string, sent: Date | null) => void
}

export const PushCampaignsTable: FC<Props> = ({ campaigns = [], onPress }) => {
  return (
    <Table>
      <TR bg="light1">
        <TH size={4}>Campaign name</TH>
        <TH size={6}>Title</TH>
        <TH size={2.5}>Channel ID</TH>
        <TH size={2}>Sent date</TH>
        <TH size={1} textAlign="center">
          Sent
        </TH>
        <TH size={1} textAlign="center">
          Pending
        </TH>
        <TH size={1} textAlign="center">
          Delivered
        </TH>
        <TH size={1} textAlign="center">
          Opened
        </TH>
        <TH size={1} textAlign="center">
          Errors
        </TH>
      </TR>

      {campaigns.map(({ id, name, title, channelId, sent, stats }, index) => (
        <Pressable key={id} onPress={() => onPress(id, sent)}>
          <TR index={index}>
            <TD size={4}>{name}</TD>
            <TD size={6}>{title}</TD>
            <TD size={2.5}>{channelId}</TD>
            <TD size={2} asText>
              {sent ? <FormattedDate value={sent} /> : '-'}
            </TD>
            <TDStat stats={stats} stat="sent" />
            <TDStat stats={stats} stat="pending" />
            <TDStat stats={stats} stat="delivered" />
            <TDStat stats={stats} stat="opened" />
            <TDStat stats={stats} stat="error" />
          </TR>
        </Pressable>
      ))}
    </Table>
  )
}

type TDStatProps = { stats: PushCampaignListItemFragment['stats']; stat: string }

const TDStat = ({ stats, stat }: TDStatProps) => {
  return (
    <TD size={1} textAlign="center" asText>
      {stats[stat]} |{' '}
      <FormattedPercent value={divideSafe(stats[stat], stats.queued)} minimumFractionDigits={0} />
    </TD>
  )
}
