import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import { Box, Button, ButtonText, Text } from '@common/components'
import { useSendPushCampaignMutation } from '../../../../graphql'

type Props = {
  id: string
  segment: string
  dirty: boolean
}

export const SendPushCampaignButtons = ({ id, segment, dirty }: Props) => {
  const history = useHistory()
  const match = useRouteMatch()
  const [openSnackbar] = useSnackbar()

  const [sendPushCampaign, { loading: sending }] = useSendPushCampaignMutation({
    variables: { id, segment },
    onCompleted: (data) => {
      const { segment } = data.sendPushCampaign
      if (segment !== 'self' && segment !== 'testers') {
        history.replace(match.url.replace('/edit', '/stats'))
      }
      openSnackbar(data.sendPushCampaign.message)
    },
    onError: (error) => openSnackbar(`Error: ${error.graphQLErrors[0]?.message}`),
  })

  if (sending) {
    return (
      <Box justifyContent="center" py="small">
        <Text>Sending…</Text>
      </Box>
    )
  }

  return (
    <>
      <Button
        onPress={() => {
          if (window.confirm("Are you sure you're ready to send this campaign?")) {
            sendPushCampaign()
          }
        }}
        disabled={dirty}
        variant="danger"
        ml="smedium"
      >
        <ButtonText>{sending ? 'Sending…' : 'Send Campaign'}</ButtonText>
      </Button>
      <Button
        onPress={() => sendPushCampaign({ variables: { id, segment: 'testers' } })}
        disabled={dirty}
        variant="gray"
        ml="smedium"
      >
        <ButtonText>Send to testers</ButtonText>
      </Button>
      <Button
        onPress={() => sendPushCampaign({ variables: { id, segment: 'self' } })}
        disabled={dirty}
        variant="gray"
        ml="smedium"
      >
        <ButtonText>Send to yourself</ButtonText>
      </Button>
    </>
  )
}
