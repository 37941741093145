import { PublicationStatus } from '../../../../graphql'

export const newBlogPostData = () => ({
  title: '',
  author: '',
  abstract: '',
  date: new Date(),
  content: JSON.stringify([]),
  status: PublicationStatus.Draft,
})
