import { createContext, useContext } from 'react'

const MainNavContext = createContext([
  { label: 'Opportunities', to: '/opportunities' },
  // { label: 'Investors', to: '/investors' },
  { label: 'Blog', to: '/blog' },
  { label: 'Push notifications', to: '/push-notifications' },
])

export const useMainNav = () => useContext(MainNavContext)

export const MainNavProvider = MainNavContext.Provider
