import { useEffect } from 'react'
import { useFonts } from 'expo-font'
import Bugsnag from '@bugsnag/js'
import { fontSources } from '@common/theme'

export function useCachedResources() {
  const [loaded, error] = useFonts(fontSources)

  useEffect(() => {
    if (error) {
      Bugsnag.notify(error)
    }
  }, [error])

  return !loaded
}
