import { FC } from 'react'
import { FieldMetaState, FieldRenderProps } from 'react-final-form'
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native'
import { PickerInput } from '@common/components'
import { DeepLinkType } from '../../../../../graphql'

const typeOptions = [
  { label: 'Opportunities list', value: DeepLinkType.Default },
  { label: 'Opportunity', value: DeepLinkType.Opportunity },
  { label: 'External URL', value: DeepLinkType.ExternalUrl },
]

interface Props {
  input: {
    name: string
    value: string
    onChange: (text: string) => void
    onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    onFocus?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
  }
  meta: FieldMetaState<string>
}

const DeepLinkTypeInput = ({ input, ...props }: Props) => {
  return (
    <PickerInput
      {...props}
      {...input}
      options={typeOptions}
      selectedValue={typeOptions.find((o) => o.value === input.value) ?? null}
      onValueChange={(option) => input.onChange(option?.value ?? typeOptions[0].value)}
    />
  )
}

// react-final-form does not have native typings, this forces
// compatibility with the Field component.
type FinalFormInput = FC<FieldRenderProps<string, HTMLElement>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeepLinkTypeFormInput = DeepLinkTypeInput as any as FinalFormInput
