import { ReactNode, useState } from 'react'
import { Pressable } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons'
import { Box, Icon } from '@common/components'
import { useContentEditorSection } from '../ContentEditorProvider'

type Props = {
  initiallyExpanded?: boolean
  preview: ReactNode
  children: ReactNode
}

export const EditorSection = ({ initiallyExpanded = false, preview, children }: Props) => {
  const [expanded, setExpanded] = useState(initiallyExpanded)
  const onToggle = () => setExpanded(!expanded)
  const {
    listMutators: { onMoveUp, onMoveDown, onDelete },
  } = useContentEditorSection()
  return (
    <Box my="smedium" borderBottomColor="dark5" borderBottomWidth={1}>
      <Box flexDirection="row" alignItems="center" mb="smedium" minHeight="smedium">
        <Pressable onPress={onToggle}>
          <Icon size="small" mr="small">
            <FontAwesome5 name={expanded ? 'chevron-down' : 'chevron-right'} />
          </Icon>
        </Pressable>

        <Box mr="auto">
          <Pressable onPress={onToggle}>{!expanded && preview}</Pressable>
        </Box>

        <Pressable onPress={onMoveUp}>
          <Icon size="small" ml="xsmall">
            <FontAwesome5 name="arrow-up" />
          </Icon>
        </Pressable>
        <Pressable onPress={onMoveDown}>
          <Icon size="small" ml="xsmall">
            <FontAwesome5 name="arrow-down" />
          </Icon>
        </Pressable>
        <Pressable onPress={onDelete}>
          <Icon size="small" ml="xsmall">
            <FontAwesome5 name="trash-alt" />
          </Icon>
        </Pressable>
      </Box>

      {expanded && children}
    </Box>
  )
}
