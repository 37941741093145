import { useCallback, useRef } from 'react'
import { useField } from 'react-final-form'
import { DocumentNode } from 'graphql'
import { Box, ImageDropzone, InputLabel, useImagePreviews } from '@common/components'
import { useApolloImageUpload } from '@common/components/src/upload/ImageUploader/useApolloImageUpload'

type Props = {
  fieldName: string
  label?: string
  width?: number
  query: DocumentNode
  imageDataPath: string
  entityGUID: string
  usage: string
  uploadFolder?: string
  onPreview?: (id: string, preview?: string) => void
}

export const FormImageUploader = ({
  fieldName,
  label,
  width,
  onPreview,
  ...imageUploadOptions
}: Props) => {
  const fileRef = useRef<File | undefined>(undefined)

  const [, uploadImage, { error }] = useApolloImageUpload({
    ...imageUploadOptions,
    onCompleted: () => {
      fileRef.current = undefined
    },
  })

  const {
    input: { value: image, onChange },
  } = useField(fieldName, {
    afterSubmit: () => {
      fileRef.current && uploadImage([fileRef.current])
    },
  })

  const { setPreview } = useImagePreviews()
  const handlePreview = useCallback(
    (preview) => {
      setPreview(image.id, preview)
      onPreview && onPreview(image.id, preview)
    },
    [image.id, setPreview, onPreview],
  )

  return (
    <Box mb="medium">
      {!!label && <InputLabel>{label}</InputLabel>}
      <ImageDropzone
        width={width}
        onDrop={([file]) => {
          fileRef.current = file
          onChange({ ...image }) // Force a dirty form so it can be submitted
        }}
        onClear={() => {
          fileRef.current = undefined
        }}
        onPreview={handlePreview}
        publicId={image?.publicId}
        error={error}
        canClearDrop
      />
    </Box>
  )
}
