import { TouchableWithoutFeedback, View } from 'react-native'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Anchor, Box, Image, Text } from '@common/components'
import { shadow } from '@common/theme'
import { useAuth } from '../../hooks/useAuth/useAuth'
import { useMainNav } from '../../hooks/useMainNav/useMainNav'
import { Link } from '../nav'

const HeaderSection = styled(View)((props) => ({
  backgroundColor: props.theme.colors.background,
  paddingHorizontal: props.theme.space.medium,
  paddingVertical: props.theme.space.small,
  marginBottom: props.theme.space.medium,
}))

const HeaderContainer = styled(View)({
  height: 40,
  flexDirection: 'row',
  alignItems: 'center',
})

const SignOutLink = styled(Anchor).attrs({
  ml: 'auto',
  color: 'dark4',
  fontSize: 'small',
})``

export const Header = () => {
  const history = useHistory()
  const navItems = useMainNav()
  const { user, signOut } = useAuth()
  return (
    <HeaderSection style={shadow.elevation4}>
      <HeaderContainer>
        <TouchableWithoutFeedback onPress={() => history.push('/')}>
          <Image mr="medium" source={{ uri: '/logo-text.png' }} width={160} height={40} />
        </TouchableWithoutFeedback>

        {navItems.map(({ label, to }) => (
          <Box key={to} px="medium">
            <Text fontWeight="semiBold">
              <Link to={to} color="foreground">
                {label}
              </Link>
            </Text>
          </Box>
        ))}

        {user && (
          <Box px="medium" ml="auto">
            <SignOutLink onPress={signOut}>Sign out</SignOutLink>
          </Box>
        )}
      </HeaderContainer>
    </HeaderSection>
  )
}
