import { FC, useEffect, useRef } from 'react'
import { FieldRenderProps, useField } from 'react-final-form'
import { FormInput } from '@common/components'
import { compileValidator } from '@common/final-form'
import { DeepLinkType } from '../../../../../graphql'
import { DeepLinkOpportunityFormInput } from './DeepLinkUrlOpportunityFormInput'

const required = compileValidator({ $$root: true, type: 'string' })

// react-final-form does not have native typings, this forces
// compatibility with the Field component.
type FinalFormInput = FC<FieldRenderProps<string, HTMLElement>>

export const DeepLinkUrlFormInput: FinalFormInput = (props) => {
  const {
    input: { value: deepLinkType },
  } = useField('deepLinkType')

  const mounted = useRef(false)
  useEffect(() => {
    if (mounted.current) {
      props.input.onChange('') // Clear the URL value when the deep link type changes
    }
    mounted.current = true
  }, [deepLinkType]) // eslint-disable-line react-hooks/exhaustive-deps

  switch (deepLinkType) {
    case DeepLinkType.Opportunity:
      return (
        <DeepLinkOpportunityFormInput
          {...props}
          label="Deep linked opportunity"
          validate={required}
        />
      )
    case DeepLinkType.ExternalUrl:
      return (
        <FormInput
          {...props}
          label="External deep link URL"
          placeholder="https://example.com"
          validate={required}
        />
      )
    default:
    case DeepLinkType.Default:
      return null
  }
}
