import { FC } from 'react'
import { FormattedDate } from '@common/components'
import { Table, TD, TH, TR } from '../../../../components'
import { PushNotificationListItemFragment } from '../../../../graphql'

type Props = {
  notifications: PushNotificationListItemFragment[]
}

export const PushNotificationsTable: FC<Props> = ({ notifications = [] }) => {
  return (
    <Table>
      <TR bg="light1">
        <TH size={2}>User</TH>
        <TH size={2}>Email</TH>
        <TH size={2}>Phone</TH>
        <TH size={1}>Scheduled</TH>
        <TH size={1}>Sent</TH>
        <TH size={1} textAlign="center">
          Delivered
        </TH>
        <TH size={1}>Opened</TH>
        <TH size={2}>Error</TH>
        <TH size={5}>Error Message</TH>
      </TR>

      {notifications.map(
        (
          {
            id,
            scheduled,
            sent,
            delivered,
            opened,
            errorCode,
            errorMessage,
            user: { displayName, email, phoneNo },
          },
          index,
        ) => (
          <TR key={id} index={index}>
            <TD size={2}>{displayName}</TD>
            <TD size={2}>{email}</TD>
            <TD size={2}>{phoneNo}</TD>
            <TDDate>{scheduled}</TDDate>
            <TDDate>{sent}</TDDate>
            <TD size={1} textAlign="center">
              {delivered ? 'Yes' : 'No'}
            </TD>
            <TDDate>{opened}</TDDate>
            <TD size={2}>{errorCode ?? ''}</TD>
            <TD size={5}>{errorMessage ?? ''}</TD>
          </TR>
        ),
      )}
    </Table>
  )
}

const TDDate = ({ children }: { children: Date | null }) => {
  return (
    <TD size={1} asText>
      {children ? (
        <FormattedDate
          value={children}
          year={undefined}
          month="short"
          day="numeric"
          hour="2-digit"
          minute="2-digit"
          second="2-digit"
        />
      ) : (
        '-'
      )}
    </TD>
  )
}
