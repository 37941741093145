import { FC } from 'react'
import { Box, BoxProps } from '@common/components'
import { Footer } from './Footer'
import { Header } from './Header'

export const Page: FC<BoxProps> = (props) => (
  <>
    <Header />
    <Box flexGrow={1} px="medium" py="small" {...props} />
    <Footer />
  </>
)
