import { random } from 'lodash'
import { ContentBlockType, ImageBlock } from '../../../../../graphql'

export const emptyImageContent = (id: string): ImageBlock => ({
  id,
  contentType: ContentBlockType.ImageBlock,
  maxWidth: null,
  caption: null,
  image: {
    id: random(0, 999999999).toString(),
    publicId: 'image-placeholder',
    width: 800,
    height: 600,
    aspectRatio: 800 / 600,
  },
})
