import { FC } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { DocumentNode } from 'graphql'
import { ContentBlock } from '../../../graphql'
import { AddEditorSection } from './ContentEditor/AddEditorSection'
import { ContentEditor } from './ContentEditor/ContentEditor'
import { ContentEditorProvider } from './ContentEditorProvider'
import { nextSectionId } from './helpers/nextSectionId'

type Props = {
  name: string
  width: number
  ownerQuery: DocumentNode
  ownerGUID: string
  ownerDataPath: string
}

export const DynamicContentEditor: FC<Props> = ({
  name,
  width,
  ownerQuery,
  ownerGUID,
  ownerDataPath,
}) => {
  return (
    <FieldArray<ContentBlock> name={name}>
      {({ fields: sections }) => (
        <>
          {sections.map((sectionFieldName, index) => (
            <ContentEditorProvider
              key={sections.value[index].id}
              fieldName={name}
              sections={sections}
              index={index}
              ownerQuery={ownerQuery}
              ownerGUID={ownerGUID}
              ownerDataPath={ownerDataPath}
            >
              <ContentEditor fieldName={sectionFieldName} width={width} />
            </ContentEditorProvider>
          ))}
          <AddEditorSection
            onAdd={(createSection) => {
              sections.push(createSection(nextSectionId(ownerGUID, sections.value)))
            }}
          />
        </>
      )}
    </FieldArray>
  )
}
