import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ButtonProps } from '@common/components'

type LinkButtonProps = ButtonProps & {
  to: string
  params?: Record<string, unknown>
}

export const LinkButton: FC<LinkButtonProps> = ({ to, ...props }) => {
  const history = useHistory()
  return <Button {...props} accessibilityRole="link" onPress={() => history.push(to)} />
}
