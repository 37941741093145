import { Field, useField } from 'react-final-form'
import { FormInput, Image } from '@common/components'
import { Image as ImageType } from '../../../../../graphql'
import { FormImageUploader } from '../../../../forms'
import { useContentEditorSection } from '../../ContentEditorProvider'
import { EditorSection } from '../EditorSection'

type Props = {
  fieldName: string
  width: number
}

export const ImageContent = ({ fieldName, width }: Props) => {
  const {
    fieldName: contentFieldName,
    ownerGUID,
    ownerQuery,
    ownerDataPath,
  } = useContentEditorSection()

  const {
    input: {
      value: { id, image },
    },
  } = useField(fieldName, { subscription: { value: true } })

  return (
    <EditorSection preview={image && <ImageContentPreview image={image} />}>
      <FormImageUploader
        fieldName={`${fieldName}.image`}
        width={width}
        query={ownerQuery}
        entityGUID={ownerGUID}
        imageDataPath={`${ownerDataPath}.${fieldName}.image`}
        usage={`${contentFieldName}_${id}`}
      />

      <Field name={`${fieldName}.caption`} placeholder="Optional caption" component={FormInput} />
    </EditorSection>
  )
}

const ImageContentPreview = ({ image: { height, ...image } }: { image: ImageType }) => {
  return <Image {...image} width={80} />
}
