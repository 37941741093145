import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, ButtonText, FullScreenLoader, H1 } from '@common/components'
import { Page } from '../../../../components'
import { usePushCampaignListPageQuery, useSavePushCampaignMutation } from '../../../../graphql'
import { PushCampaignsTable } from './PushCampaignsTable'
import { newPushCampaignData } from './newPushCampaignData'

const NewPostButton = styled(Button)((props) => ({
  position: 'absolute',
  top: props.theme.space.smedium,
  right: props.theme.space.medium,
}))

type Props = { path: string }

export const PushCampaignListPage = ({ path }: Props) => {
  const history = useHistory()
  const { loading, data } = usePushCampaignListPageQuery()

  const [savePushCampaign] = useSavePushCampaignMutation({
    ignoreResults: true,
    onCompleted: (data) => history.push(`${path}/${data.savePushCampaign.pushCampaign.id}/edit`),
  })

  if (loading || !data) {
    return <FullScreenLoader />
  }

  return (
    <Page>
      <H1>Push Campaigns</H1>
      <NewPostButton
        onPress={() => savePushCampaign({ variables: { input: newPushCampaignData() } })}
      >
        <ButtonText>New Campaign</ButtonText>
      </NewPostButton>

      <PushCampaignsTable
        campaigns={data.pushCampaigns}
        onPress={(campaignId, sent) =>
          sent
            ? history.push(`${path}/${campaignId}/stats`)
            : history.push(`${path}/${campaignId}/edit`)
        }
      />
    </Page>
  )
}
