import { FC } from 'react'
import { formatISO, parseJSON } from 'date-fns'
import { FormattedMoney } from '@common/components'
import { Table, TR, TH, TD } from '../../../../../components'
import { InvestorTableProfileFragment, InvestorTableUserFragment } from '../../../../../graphql'

type Props = {
  investors: {
    user: InvestorTableUserFragment
    investorProfile: InvestorTableProfileFragment
  }[]
}

export const InvestorsTable: FC<Props> = ({ investors = [] }) => {
  return (
    <Table>
      <TR bg="light1">
        <TH size={1.5}>Registered</TH>
        <TH size={3}>Name</TH>
        <TH size={4}>Email</TH>
        <TH size={3}>Phone</TH>
        <TH size={2}>What brought you</TH>
        <TH size={2}>Experience</TH>
        <TH size={2}>Frequency</TH>
        <TH size={2}>Specifics</TH>
        <TH size={2}>Priorities</TH>
        <TH size={1.5}>Risk</TH>
        <TH size={1} textAlign="center">
          Shariah
        </TH>
      </TR>

      {investors.map(({ user, investorProfile: profile }, index) => (
        <TR key={user.id} index={index}>
          <TD size={1.5}>{formatISO(parseJSON(user.registered), { representation: 'date' })}</TD>
          <TD size={3}>{user.displayName}</TD>
          <TD size={4}>{user.email}</TD>
          <TD size={3}>{user.phoneNo}</TD>
          <TD size={2}>{profile.whatBroughtYouDescription ?? ''}</TD>
          <TD size={2}>{profile.experienceLevelDescription}</TD>
          <TD size={2}>{profile.investmentFrequencyDescription}</TD>
          <TD size={2} asText>
            {(profile.investmentFrequencySpecifics ?? 0) > 50 ? (
              <FormattedMoney
                currency={profile.currency.currency}
                value={profile.investmentFrequencySpecifics}
              />
            ) : (
              profile.investmentFrequencySpecifics
            )}
          </TD>
          <TD size={2}>{profile.investmentPrioritiesDescription}</TD>
          <TD size={1.5}>{profile.riskAppetiteDescription}</TD>
          <TD size={1} textAlign="center">
            {profile.shariah ? 'Y' : 'N'}
          </TD>
        </TR>
      ))}
    </Table>
  )
}
