import { Box, PickerInput } from '@common/components'
import { ContentBlock } from '../../../../graphql'
import { emptyImageContent } from './ImageContent/emptyImageContent'
import { emptyRichTextContent } from './RichTextContent/emptyRichTextContent'

type OptionType = { label: string; emptyContent?: (id: string) => ContentBlock }

const options: OptionType[] = [
  { label: 'Text section', emptyContent: emptyRichTextContent },
  { label: 'Image section', emptyContent: emptyImageContent },
]

type Props = {
  onAdd: (createSection: (id: string) => ContentBlock) => void
}

export const AddEditorSection = ({ onAdd }: Props) => {
  return (
    <Box my="smedium" borderBottomColor="dark5" borderBottomWidth={1}>
      <Box mb="smedium" minHeight="smedium">
        <PickerInput<OptionType>
          width={0.75}
          placeholder={{ label: 'Add a new section...' }}
          options={options}
          selectedValue={null}
          onValueChange={(option) => option?.emptyContent && onAdd(option.emptyContent)}
        />
      </Box>
    </Box>
  )
}
