import * as firebase from 'firebase/app'
import 'firebase/auth'

const stagingConfig = {
  apiKey: 'AIzaSyBScgL_x5vkCtGUGra5LgoIIB3vHysad_Q',
  authDomain: 'property-clan-staging.firebaseapp.com',
  databaseURL: 'https://property-clan-staging.firebaseio.com',
  projectId: 'property-clan-staging',
  storageBucket: 'property-clan-staging.appspot.com',
  messagingSenderId: '502915109051',
  appId: '1:502915109051:web:7c8eeff342ab8f8a51999e',
  measurementId: 'G-E8SEBLVHR8',
}

const productionConfig = {
  apiKey: 'AIzaSyD8V_qrwd5EzhD5QApen8iGRFwaUAVpgAY',
  authDomain: 'canvas-sum-270014.firebaseapp.com',
  databaseURL: 'https://canvas-sum-270014.firebaseio.com',
  projectId: 'canvas-sum-270014',
  storageBucket: 'canvas-sum-270014.appspot.com',
  messagingSenderId: '555212384426',
  appId: '1:555212384426:web:c95057c36fdf52c32808d3',
  measurementId: 'G-Z3P8YYPFDX',
}

if (!firebase.apps.length) {
  firebase.initializeApp(process.env.NODE_ENV === 'production' ? productionConfig : stagingConfig)
}
