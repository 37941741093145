import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { BlogPostEditorPage } from './pages/BlogPostEditorPage/BlogPostEditorPage'
import { BlogPostListPage } from './pages/BlogPostListPage/BlogPostListPage'

export const Blog = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match.url}/articles`}>
        <BlogPostListPage path={match.path} />
      </Route>
      <Route exact path={`${match.url}/article/:id`}>
        <BlogPostEditorPage />
      </Route>
      <Redirect to={`${match.url}/articles`} />
    </Switch>
  )
}
