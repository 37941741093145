import { useState } from 'react'
import { Box, Button, ButtonText, FileDropzone, H1, Image, Paragraph } from '@common/components'
import { DropState, useUploadCSV } from './useUploadCSV'

export const InceLeadsPage = () => {
  const [droppedFile, setDroppedFile] = useState<DropState | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const onDrop = ([file]: File[]) => {
    const reader = new FileReader()
    reader.readAsText(file, 'utf8')
    reader.onload = (evt) => {
      const [headings, ...records] = (evt.target?.result as string)?.split('\n').filter(Boolean)
      setDroppedFile({ file, headings, records })
      setError(null)
    }
    reader.onerror = () => setError(new Error('There was an error parsing the file'))
  }

  const [upload, { isLoading, isSuccess }] = useUploadCSV({
    onError: setError,
    onCompleted: setDroppedFile,
  })

  return (
    <Box flexGrow={1} px="medium" py="small">
      <Image
        style={{ width: 200, height: Math.floor((100 * 200) / 400) }}
        source={{ uri: '/logo-text.png' }}
        mb="medium"
      />
      <Box
        flex={1}
        flexDirection="column"
        alignItems="stretch"
        width="100%"
        maxWidth={800}
        mx="auto"
      >
        <Box
          marginTop={150}
          padding="large"
          borderRadius="medium"
          borderColor="border"
          borderWidth={1}
        >
          <H1>Ince CSV Upload</H1>

          <FileDropzone
            accept={['text/csv', 'application/vnd.ms-excel']}
            onDrop={onDrop}
            width="100%"
            height={150}
            hint="Drag 'n' drop a CSV file or click to select"
          />

          {error && (
            <Box mt="medium">
              <Paragraph color="error">{error.message}</Paragraph>
            </Box>
          )}

          {isSuccess && (
            <Box mt="medium">
              <Paragraph color="primary" textAlign="center">
                The file was uploaded successfully
              </Paragraph>
            </Box>
          )}

          {droppedFile && (
            <Box mt="medium">
              <Paragraph>File: {droppedFile.file.name}</Paragraph>
              <Paragraph>Records found: {droppedFile.records.length}</Paragraph>

              <Button onPress={() => upload(droppedFile)} disabled={isLoading}>
                <ButtonText>{isLoading ? 'Submitting' : 'Submit'}</ButtonText>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
