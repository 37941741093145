import { useFormState } from 'react-final-form'
import { Text, View } from 'react-native'
import styled from 'styled-components'
import { Image } from '@common/components'
import { PushCampaign, PushCampaignPreviewFragment } from '../../../../../graphql'
import icon from './app-icon.png'

const Container = styled(View)({
  backgroundColor: '#555',
  borderWidth: 1,
  borderColor: '#bbb',
  borderRadius: 12,
  padding: 12,
  paddingBottom: 14,
  marginLeft: -12,
  marginRight: -12,
})

const MessageHead = styled(View)({
  flexDirection: 'row',
  marginBottom: 7,
})

const Icon = styled(Image)({
  width: 18,
  height: 18,
  backgroundColor: '#777',
  borderRadius: 4,
  marginRight: 6,
})

const Sender = styled(Text)({
  fontSize: 13,
  color: '#aaa',
  textTransform: 'uppercase',
})

const SentAt = styled(Text)({
  fontSize: 12,
  color: '#aaa',
  marginLeft: 'auto',
})

const Title = styled(Text)({
  color: '#fff',
  fontSize: 13,
  fontWeight: 600,
  marginBottom: 1,
})

const Body = styled(Text)({
  color: '#fff',
  fontSize: 13,
})

type Props = { campaign: PushCampaignPreviewFragment }

export const PushCampaignPreviewIOS = ({ campaign }: Props) => {
  return (
    <Container>
      <MessageHead>
        <Icon source={icon} width={18} height={18} />
        <Sender>Property Clan</Sender>
        <SentAt>7m ago</SentAt>
      </MessageHead>
      <Title>{campaign.title}</Title>
      <Title>{campaign.subTitle}</Title>
      <Body>{campaign.body}</Body>
    </Container>
  )
}

export const PushCampaignPreviewIOSField = () => {
  const { values } = useFormState<PushCampaign>({ subscription: { values: true } })
  return <PushCampaignPreviewIOS campaign={values} />
}
