import { FC } from 'react'
import { Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { PublicOnlyRoute } from '../router'
import { SignIn } from './SignIn'

export const Auth: FC = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <PublicOnlyRoute exact path={`${match.path}/sign-in`} component={SignIn} />
      <Redirect to={`${match.path}/`} />
    </Switch>
  )
}
