import { FC } from 'react'
import { Box, Image } from '@common/components'

export const AuthPageOutline: FC = ({ children }) => (
  <Box mt="xxxlarge" alignItems="center">
    <Box padding="large" borderRadius="medium" borderColor="border" borderWidth={1}>
      <Image
        style={{ width: 350, height: Math.floor((100 * 350) / 400) }}
        source={{ uri: '/logo-text.png' }}
        mb="medium"
      />
      {children}
    </Box>
  </Box>
)
