import { useRouteMatch } from 'react-router-dom'
import { groupBy } from 'lodash'
import { Box, ButtonText, H1, H2, Text } from '@common/components'
import { OpportunityBuyToLetPreview, OpportunityMultiLetPreview } from '@common/domain'
import { Page } from '../../../../components'
import { LinkButton } from '../../../../components/nav/LinkButton/LinkButton'
import { useOpportunitiesPageListQuery } from '../../../../graphql'

export const OpportunitiesPage = () => {
  const { path } = useRouteMatch()

  const { loading, data } = useOpportunitiesPageListQuery()

  const grouped = groupBy(data?.opportunities, 'opportunityType')
  const width = 320

  return (
    <Page>
      <H1>Opportunities</H1>
      {loading && <Text>Loading...</Text>}

      {!loading && (
        <>
          <H2>Buy-to-let Opportunities</H2>
          <Box flexDirection="row" mb="large">
            {grouped?.buyToLet?.map((opportunity) => (
              <Box mr="large" key={opportunity.id}>
                <OpportunityBuyToLetPreview opportunity={opportunity} width={width}>
                  <LinkButton to={`${path}/${opportunity.id}`} btnSize="large" mt="auto">
                    <ButtonText>View Details</ButtonText>
                  </LinkButton>
                </OpportunityBuyToLetPreview>
              </Box>
            ))}
          </Box>

          <H2>Multi-let Opportunities</H2>
          <Box flexDirection="row" mb="large">
            {grouped?.multiLet?.map((opportunity) => (
              <Box mr="large" key={opportunity.id}>
                <OpportunityMultiLetPreview opportunity={opportunity} width={width}>
                  <LinkButton to={`${path}/${opportunity.id}`} btnSize="large" mt="auto">
                    <ButtonText>View Details</ButtonText>
                  </LinkButton>
                </OpportunityMultiLetPreview>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Page>
  )
}
