import { ReactNode, useState } from 'react'
import { Pressable } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons'
import { Box, H3, Icon } from '@common/components'

type Props = {
  title: string
  initiallyExpanded?: boolean
  collapsable?: boolean
  children: ReactNode
}

export const FormCollapsibleSection = ({
  title,
  initiallyExpanded = true,
  collapsable = true,
  children,
}: Props) => {
  const [expanded, setExpanded] = useState(initiallyExpanded)
  const onToggle = () => setExpanded(!expanded)
  return (
    <Box my="small" borderBottomColor="dark5" borderBottomWidth={1}>
      <Box flexDirection="row" alignItems="center" mb="smedium">
        {collapsable && (
          <Pressable onPress={onToggle}>
            <Icon size="small" mr="small">
              <FontAwesome5 name={expanded ? 'chevron-down' : 'chevron-right'} />
            </Icon>
          </Pressable>
        )}

        <H3 mb="none" color="foreground">
          {title}
        </H3>
      </Box>

      {expanded && children}
    </Box>
  )
}
