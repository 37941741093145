import { useRouteMatch } from 'react-router-dom'
import { Box, FullScreenLoader, H1, H2 } from '@common/components'
import { Page } from '../../../../components'
import { usePushCampaignStatsPageQuery } from '../../../../graphql'
import { PushCampaignPreviewAndroid } from '../PushCampaignEditorPage/previews/PushCampaignPreviewAndroid'
import { PushCampaignPreviewIOS } from '../PushCampaignEditorPage/previews/PushCampaignPreviewIOS'
import { PushNotificationsTable } from './PushNotificationsTable'
import { StatsBox } from './StatsBox'

const previewWidth = 375

export const PushCampaignStatsPage = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const { loading, data } = usePushCampaignStatsPageQuery({ variables: { id } })

  if (loading || !data?.pushCampaign) {
    return <FullScreenLoader />
  }

  const { pushCampaign } = data
  const { name, stats, notifications } = pushCampaign

  return (
    <Page>
      <H1>Push Campaign | {name}</H1>

      <Box flexDirection="row" mt="medium" mb="xlarge">
        <Box width={previewWidth} ml="smedium" mr="large">
          <PushCampaignPreviewAndroid campaign={pushCampaign} />
        </Box>
        <Box width={previewWidth} ml="smedium" mr="large">
          <PushCampaignPreviewIOS campaign={pushCampaign} />
        </Box>
      </Box>

      <Box flexDirection="row" mt="small" mb="xlarge">
        <StatsBox label="Sent" value={stats.sent} total={stats.queued} color="#b5deef" />
        {!!stats.pending && (
          <StatsBox label="Pending" value={stats.pending} total={stats.queued} color="#f7cdb0" />
        )}
        <StatsBox label="Delivered" value={stats.delivered} total={stats.queued} color="#fff4b8" />
        <StatsBox label="Opened" value={stats.opened} total={stats.queued} color="#bcefbf" />
        <StatsBox label="Errors" value={stats.error} total={stats.queued} color="#ffc0c0" />
      </Box>

      <H2>Recipients</H2>
      <PushNotificationsTable notifications={notifications} />
    </Page>
  )
}
