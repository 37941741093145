import { forwardRef, ReactElement, SyntheticEvent, useCallback, useState } from 'react'
import { Editor, EditorProps } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import styled, { useTheme } from 'styled-components'
import { Box, BoxProps, InputError, InputHint, InputLabel, TextBox } from '@common/components'
import { FontWeight } from '@common/theme'
import './HtmlInput.css'

export type HtmlInputProps = BoxProps &
  EditorProps & {
    /** Height of the editor area */
    editorHeight?: number
    /** A user cannot enter text when the input is disabled */
    disabled?: boolean
    /** When present the input is styled to represent a validation error state */
    error?: string
    /** A text label above the input instead of a placeholder */
    label?: string
    /** Hint text shown beneath the input, a string or nested `Anchor` elements */
    hint?: string | ReactElement
    /** The HTML editor is uncontrolled and accepts an initial value; to force an external update change the key prop */
    initialValue?: string
    /**
     * Callback that is called when the text input's text changes.
     * Changed text is passed as an argument to the callback handler.
     * Alias of `onChangeText` for consistency with other input types.
     */
    onValueChange: (text: string) => void
    /** A font weight other than the default `semiBold` */
    labelFontWeight?: FontWeight
    /** Native typing */
    onFocus?: (ev: SyntheticEvent) => void
    /** Native typing */
    onBlur?: (ev: SyntheticEvent) => void
  }

const HtmlTextBox = styled(TextBox)((props) => ({
  padding: props.theme.space.xsmall,
}))

// https://jpuri.github.io/react-draft-wysiwyg/#/docs
const toolbar = {
  options: ['inline', 'blockType', 'remove', 'history', 'list', 'textAlign'],
  inline: {
    options: ['bold', 'italic', 'superscript'],
  },
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H2', 'H3'],
    className: 'blockType',
  },
  list: {
    options: ['unordered', 'ordered'],
  },
}

export const HtmlInput = forwardRef<Editor, HtmlInputProps>(
  (
    {
      label,
      hint,
      error,
      disabled,
      readOnly,
      initialValue,
      onValueChange,
      onFocus: parentOnFocus,
      onBlur: parentOnBlur,
      labelFontWeight,
      editorHeight = 350,
      mb = 'smedium',
      ...editorProps
    },
    ref,
  ) => {
    const theme = useTheme()

    const [editorState, setEditorState] = useState<EditorState>(() => {
      if (!initialValue) {
        return EditorState.createEmpty()
      }
      const { contentBlocks, entityMap } = htmlToDraft(initialValue)
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocks, entityMap),
      )
    })

    const onEditorStateChange = useCallback(
      (editorState: EditorState) => {
        setEditorState(editorState)
        onValueChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
      },
      [onValueChange],
    )

    const [focused, setFocus] = useState(false)
    const onFocus = useCallback(
      (ev: SyntheticEvent) => {
        setFocus(true)
        parentOnFocus && parentOnFocus(ev)
      },
      [parentOnFocus],
    )
    const onBlur = useCallback(
      (ev: SyntheticEvent) => {
        setFocus(false)
        parentOnBlur && parentOnBlur(ev)
      },
      [parentOnBlur],
    )

    return (
      <Box mb={mb}>
        {!!label && <InputLabel fontWeight={labelFontWeight}>{label}</InputLabel>}
        <HtmlTextBox error={error} focused={focused}>
          <Editor
            ref={ref}
            wrapperStyle={{ width: '100%', display: 'block' }}
            editorStyle={{
              height: editorHeight,
              borderWidth: 1,
              borderStyle: 'solid',
              // @ts-ignore todo Why no theme typings?
              borderColor: theme.colors.light3,
              // @ts-ignore todo Why no theme typings?
              padding: theme.space.xsmall,
              borderRadius: 2,
            }}
            // @ts-ignore Mismatch in third party libraries
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbar}
            readOnly={readOnly || disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            {...editorProps}
          />
        </HtmlTextBox>
        {!!error && <InputError>{error}</InputError>}
        {!!hint && <InputHint>{hint}</InputHint>}
      </Box>
    )
  },
)
