import { FC } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useHistory } from 'react-router-dom'
import { Anchor, TextProps } from '@common/components'

type Props = {
  to?: string
  href?: string
} & TextProps

export const Link: FC<Props> = ({ to, href, ...props }) => {
  const history = useHistory()
  const onPress = (ev: GestureResponderEvent) => {
    if (to) {
      ev.preventDefault()
      history.push(to)
    }
  }
  return <Anchor {...props} onPress={onPress} href={href ?? to} />
}
