import { FC, forwardRef, SyntheticEvent } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { FieldMetaState, FieldRenderProps } from 'react-final-form'
import { HtmlInput, HtmlInputProps } from '../../input/HtmlInput/HtmlInput'

interface NativeHtmlFieldRenderProps extends Omit<HtmlInputProps, 'onValueChange'> {
  input: {
    name: string
    value: string
    onChange: (text: string) => void
    onBlur?: (e: SyntheticEvent) => void
    onFocus?: (e: SyntheticEvent) => void
  }
  meta: FieldMetaState<string>
}

const NativeFormHtml = forwardRef<Editor, NativeHtmlFieldRenderProps>(
  (
    {
      input: { value, onChange, onFocus, onBlur },
      meta: { touched, error, submitError },
      ...props
    },
    ref,
  ) => (
    <HtmlInput
      ref={ref}
      onBlur={onBlur}
      onFocus={onFocus}
      initialValue={value}
      error={touched && (submitError || error)}
      {...props}
      onValueChange={onChange}
    />
  ),
)

// react-final-form does not have native typings, this forces
// compatibility with the Field component.
type FinalFormInput = FC<
  FieldRenderProps<string, HTMLElement> & Omit<HtmlInputProps, 'onValueChange'>
>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormHtml = NativeFormHtml as any as FinalFormInput
