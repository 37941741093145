import { Switch, Route, Redirect } from 'react-router-dom'
import { Auth } from './auth'
import { Blog } from './blog'
import { CsvUpload } from './csv-upload'
import { Investors } from './investors'
import { Opportunities } from './opportunities'
import { PushNotifications } from './push-notifications'
import { AdminRoute } from './router'

export const Routes = () => {
  return (
    <Switch>
      <Route path="/auth">
        <Auth />
      </Route>
      <AdminRoute path="/blog">
        <Blog />
      </AdminRoute>
      <AdminRoute path="/investors">
        <Investors />
      </AdminRoute>
      <AdminRoute path="/opportunities">
        <Opportunities />
      </AdminRoute>
      <AdminRoute path="/push-notifications">
        <PushNotifications />
      </AdminRoute>
      <Route path="/csv-upload">
        <CsvUpload />
      </Route>
      <Redirect to="/opportunities" />
    </Switch>
  )
}
