import { createContext, ReactNode, useContext } from 'react'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { DocumentNode } from 'graphql'
import { noop } from 'lodash'
import { ContentBlock } from '../../../graphql'

const ContentEditorContext = createContext({
  fieldName: '',
  ownerQuery: {} as DocumentNode,
  ownerGUID: '',
  ownerDataPath: '',
  listMutators: {
    onDelete: noop,
    onMoveUp: noop,
    onMoveDown: noop,
  },
})

type Props = {
  fieldName: string
  sections: FieldArrayRenderProps<ContentBlock, HTMLElement>['fields']
  ownerQuery: DocumentNode
  ownerGUID: string
  ownerDataPath: string
  index: number
  children: ReactNode
}

export const ContentEditorProvider = ({
  fieldName,
  sections,
  ownerQuery,
  ownerGUID,
  ownerDataPath,
  index,
  children,
}: Props) => {
  const listMutators = {
    onDelete: () => confirmDelete() && sections.remove(index),
    onMoveUp: () => index > 0 && sections.swap(index, index - 1),
    onMoveDown: () => index < sections.value.length - 1 && sections.swap(index, index + 1),
  }

  return (
    <ContentEditorContext.Provider
      value={{
        fieldName,
        ownerQuery,
        ownerGUID,
        ownerDataPath,
        listMutators,
      }}
    >
      {children}
    </ContentEditorContext.Provider>
  )
}

export const useContentEditorSection = () => useContext(ContentEditorContext)

const confirmDelete = () =>
  window.confirm(
    "Remove this section entirely? This can't be undone unless you revert all your changes.",
  )
