import { H1, Text } from '@common/components'
import { Page } from '../../../../components'
import { useInvestorsPageListQuery } from '../../../../graphql'
import { InvestorsTable } from './InvestorsTable/InvestorsTable'

export const InvestorsPage = () => {
  const { loading, data } = useInvestorsPageListQuery()

  return (
    <Page>
      <H1>Investors</H1>
      {loading && <Text>Loading...</Text>}

      {data && <InvestorsTable investors={data.investors} />}
    </Page>
  )
}
