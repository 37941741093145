import { FlatList } from 'react-native'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, ButtonText, FullScreenLoader, H1 } from '@common/components'
import { MediaDimensionsProvider } from '@common/media'
import { Page } from '../../../../components'
import { useBlogPostListPageQuery, useSaveBlogPostMutation } from '../../../../graphql'
import { BlogPostListItem } from './BlogPostListItem'
import { newBlogPostData } from './newBlogPostData'

const NewPostButton = styled(Button)((props) => ({
  position: 'absolute',
  top: props.theme.space.smedium,
  right: props.theme.space.medium,
}))

type Props = { path: string }

export const BlogPostListPage = ({ path }: Props) => {
  const history = useHistory()
  const { loading, data } = useBlogPostListPageQuery()

  const [saveBlogPost] = useSaveBlogPostMutation({
    ignoreResults: true,
    onCompleted: (data) => history.push(`${path}/article/${data.saveBlogPost.blogPost.id}`),
  })

  if (loading || !data) {
    return <FullScreenLoader />
  }

  return (
    <Page>
      <MediaDimensionsProvider width={1200}>
        <H1>Blog Posts</H1>
        <NewPostButton onPress={() => saveBlogPost({ variables: { input: newBlogPostData() } })}>
          <ButtonText>Add a blog post</ButtonText>
        </NewPostButton>

        <FlatList
          data={data.blogPosts}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <BlogPostListItem
              {...item}
              onPress={() => history.push(`${path}/article/${item.id}`)}
            />
          )}
        />
      </MediaDimensionsProvider>
    </Page>
  )
}
