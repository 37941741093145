import { Box, FormattedPercent, Text } from '@common/components'

type Props = {
  label: string
  value: number
  total?: number
  color?: string
}

export const StatsBox = ({ label, value, total, color }: Props) => (
  <Box borderRadius="large" p="smedium" minWidth={150} mr="large" alignItems="flex-end" bg={color}>
    <Text fontSize="small" mb="xxsmall">
      {label}
    </Text>
    <Text fontSize="xxxlarge" lineHeight="xxlarge">
      {!!total && (
        <Text fontSize="medium">
          <FormattedPercent value={value / total} minimumFractionDigits={0} />
        </Text>
      )}
      &nbsp;
      {value}
    </Text>
  </Box>
)
