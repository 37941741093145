import { Box, Button, ButtonText, Text } from '@common/components'
import { useAuth } from '../../hooks/useAuth/useAuth'
import { AuthPageOutline } from './components/AuthPageOutline'

export const Unauthorized = () => {
  const { signOut } = useAuth()
  return (
    <AuthPageOutline>
      <Box py="large" px="medium" maxWidth={350}>
        <Text textAlign="center">
          You are not an administrator. Please use a different account.
        </Text>
      </Box>

      <Button block variant="primary" btnSize="large" onPress={signOut}>
        <ButtonText>Sign Out</ButtonText>
      </Button>
    </AuthPageOutline>
  )
}
