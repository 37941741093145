import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { PushCampaignEditorPage } from './pages/PushCampaignEditorPage/PushCampaignEditorPage'
import { PushCampaignListPage } from './pages/PushCampaignListPage/PushCampaignListPage'
import { PushCampaignStatsPage } from './pages/PushCampaignStatsPage/PushCampaignStatsPage'

export const PushNotifications = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/:id/edit`}>
        <PushCampaignEditorPage />
      </Route>
      <Route path={`${match.url}/:id/stats`}>
        <PushCampaignStatsPage />
      </Route>
      <Route path={`${match.url}`}>
        <PushCampaignListPage path={match.path} />
      </Route>
      <Redirect to={`${match.url}`} />
    </Switch>
  )
}
