/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T extends PromiseLike<infer U> ? Promise<U | null> : T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  GUID: string;
  JSON: any;
  Time: any;
};






export type Address = {
  unitNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  premise?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  sublocality?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  adminAreaLevel1?: Maybe<Scalars['String']>;
  adminAreaLevel2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  formattedAddress?: Maybe<Scalars['String']>;
};

export type AfterTaxCashFlows = {
  incomeTaxRate: Scalars['Float'];
  unleveredIncomeTax: Scalars['Int'];
  unleveredDeferredTax: Scalars['Int'];
  unleveredDeferredTaxMovement: Scalars['Int'];
  unleveredIncomeTaxPaid: Scalars['Int'];
  unleveredFreeCash: Scalars['Int'];
  freeCashFlowConversion: Scalars['Int'];
  leveredIncomeTax: Scalars['Int'];
  leveredDeferredTax: Scalars['Int'];
  leveredDeferredTaxMovement: Scalars['Int'];
  leveredIncomeTaxPaid: Scalars['Int'];
  debtServiceFreeCash: Scalars['Int'];
  debtServiceObligation: Scalars['Int'];
  leveredFreeCash: Scalars['Int'];
};

export type AssetGrowth = {
  baseCost: Scalars['Int'];
  assetValue: Scalars['Int'];
  capitalGrowthRate: Scalars['Float'];
  capitalGains: Scalars['Int'];
  capitalGainsTaxRate: Scalars['Float'];
  capitalGainsTaxLiability: Scalars['Int'];
};

export type Badge = {
  key: Scalars['String'];
  title: Scalars['String'];
  color: Scalars['String'];
};

export type BlogPost = {
  id: Scalars['GUID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  author: Scalars['String'];
  date: Scalars['DateTime'];
  status: PublicationStatus;
  featureImage: Image;
  abstract: Scalars['String'];
  content: Array<ContentBlock>;
};

export type BlogPostInput = {
  id?: Maybe<Scalars['GUID']>;
  title?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<PublicationStatus>;
  abstract?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
};

export type BondRepayments = {
  fixedInterestRate: Scalars['Float'];
  interestRateMargin: Scalars['Float'];
  interestRate: Scalars['Float'];
  monthlyRepayment: Scalars['Int'];
  bondMonthlyFee: Scalars['Int'];
  openingBalance: Scalars['Int'];
  interestAccrued: Scalars['Int'];
  interestPaid: Scalars['Int'];
  mandatoryCapitalPayment: Scalars['Int'];
  balance: Scalars['Int'];
  remainingTerm: Scalars['Int'];
};

export type BondSpec = {
  ltv: Scalars['Float'];
  term: Scalars['Int'];
  fixedRate?: Maybe<Scalars['Float']>;
  relativeRate?: Maybe<Scalars['Float']>;
  initiationFee: Scalars['Int'];
  initiationFeeCapitalised: Scalars['Boolean'];
  balance: Scalars['Int'];
  repayment: Scalars['Int'];
  serviceFee: Scalars['Int'];
};

export type BuyToLetFinancials = {
  opportunityType: OpportunityType;
  currency: Currency;
  leaseTypes: Array<LeaseType>;
  leaseType: LeaseType;
  price: Scalars['Int'];
  pricePerSqm: Scalars['Int'];
  marketValue: Scalars['Int'];
  discount: Scalars['Int'];
  discountPercent: Scalars['Float'];
  occupancyRate: Scalars['Float'];
  rentDayRate: Scalars['Int'];
  rentAmount: Scalars['Int'];
  rentEscalation: Scalars['Float'];
  rentExpires?: Maybe<Scalars['Date']>;
  grossYield: Scalars['Float'];
  vacancyFrequency: Scalars['Int'];
  directCostRows: Array<DirectCostRow>;
  directCosts: Scalars['Float'];
  revenue: Scalars['Int'];
  propertyCostRows: Array<PropertyCostRow>;
  propertyCosts: Scalars['Float'];
  /** @deprecated Use propertyCostRows */
  levies: Scalars['Int'];
  /** @deprecated Use propertyCostRows */
  leviesConcession: Scalars['Int'];
  /** @deprecated Use propertyCostRows */
  taxes: Scalars['Int'];
  netRevenue: Scalars['Int'];
  netYield: Scalars['Float'];
  managementCommission: Scalars['Float'];
  managementAmount: Scalars['Int'];
  maintenanceProvision: Scalars['Int'];
  insurancePremium: Scalars['Int'];
  bond?: Maybe<BondSpec>;
  monthlyCashFlow: Scalars['Int'];
  discretionaryMonthlyCashFlow: Scalars['Int'];
  discretionaryNetYield: Scalars['Float'];
  securingDeposit: Scalars['Int'];
  securingDepositRefund: Scalars['Int'];
  bondRegistrationFee: Scalars['Int'];
  conveyancingFee: Scalars['Int'];
  transferDuty: Scalars['Int'];
  furniturePackage: Scalars['Int'];
  initialInvestment: Scalars['Int'];
  holdPeriod: Scalars['Int'];
  irr: Scalars['Float'];
  moneyBackReturn: Scalars['Float'];
};

export type BuyToLetPeriod = {
  timeline: Timeline;
  macroEconomics: MacroEconomics;
  assetGrowth: AssetGrowth;
  bondRepayment: BondRepayments;
  cashFlows: CashFlows;
  afterTaxCashFlows: AfterTaxCashFlows;
  returns: Returns;
};

export type CashFlows = {
  rentAmount: Scalars['Int'];
  rentEscalation: Scalars['Float'];
  rentTotalMonths: Scalars['Int'];
  rentCurrentMonth: Scalars['Int'];
  rentAnniversary: Scalars['Boolean'];
  marketRentAmount: Scalars['Int'];
  marketRentEscalation: Scalars['Float'];
  vacancyInterval: Scalars['Float'];
  vacancyMonthsSince: Scalars['Int'];
  vacancyAmount: Scalars['Int'];
  revenue: Scalars['Int'];
  revenueGrowth: Scalars['Float'];
  taxes: Scalars['Int'];
  levies: Scalars['Int'];
  leviesConcession: Scalars['Int'];
  propertyCosts: Scalars['Int'];
  netRevenue: Scalars['Int'];
  netRevenueMargin: Scalars['Float'];
  bondServiceFee: Scalars['Int'];
  monthlyCashFlow: Scalars['Int'];
  maintenanceProvision: Scalars['Int'];
  managementCommission: Scalars['Float'];
  managementAmount: Scalars['Int'];
  discretionaryCosts: Scalars['Int'];
  operatingProfit: Scalars['Int'];
  operatingProfitMargin: Scalars['Float'];
  operatingProfitGrowth: Scalars['Float'];
  discretionaryMonthlyCashFlow: Scalars['Int'];
  profitBeforeTax: Scalars['Int'];
  interestExpense: Scalars['Int'];
  leveredTaxableProfit: Scalars['Int'];
};

export type ContentBlock = RichTextBlock | ImageBlock | ImageGridBlock | ImageCarouselBlock | VideoBlock | MapBlock | VirtualTourBlock;

export enum ContentBlockType {
  RichTextBlock = 'RichTextBlock',
  ImageBlock = 'ImageBlock',
  ImageGridBlock = 'ImageGridBlock',
  ImageCarouselBlock = 'ImageCarouselBlock',
  VideoBlock = 'VideoBlock',
  MapBlock = 'MapBlock',
  VirtualTourBlock = 'VirtualTourBlock'
}

export type Coordinate = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Currency = {
  currency: Scalars['String'];
  symbol: Scalars['String'];
};

export type CurrencyInput = {
  currency: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};



export type DeclineOpportunityInput = {
  id: Scalars['GUID'];
  declineReason: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
};

export enum DeepLinkType {
  Default = 'Default',
  Opportunity = 'Opportunity',
  ExternalUrl = 'ExternalURL'
}

export enum DeviceOs {
  Android = 'android',
  Ios = 'ios'
}

export type DirectCostRow = {
  slug: Scalars['String'];
  amount: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type Document = {
  name: Scalars['String'];
  mime: Scalars['String'];
  uri: Scalars['String'];
};


export type Image = {
  id: Scalars['GUID'];
  publicId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  aspectRatio: Scalars['Float'];
};

export type ImageBlock = {
  id: Scalars['ID'];
  contentType: ContentBlockType;
  image: Image;
  maxWidth?: Maybe<Scalars['Float']>;
  caption?: Maybe<Scalars['String']>;
};

export type ImageCarouselBlock = {
  id: Scalars['ID'];
  contentType: ContentBlockType;
  images: Array<Image>;
  caption?: Maybe<Scalars['String']>;
};

export type ImageGridBlock = {
  id: Scalars['ID'];
  contentType: ContentBlockType;
  images: Array<Image>;
};

export enum InvestmentFrequency {
  Maximum = 'maximum',
  SpecificGoal = 'specificGoal',
  SpecificAmount = 'specificAmount',
  Minimum = 'minimum',
  Unsure = 'unsure'
}

export enum InvestmentPriorities {
  CapitalGrowth = 'capitalGrowth',
  CashFlow = 'cashFlow',
  RentalYield = 'rentalYield',
  RiskManagement = 'riskManagement'
}

/** Summary of investment types an investor is interested in */
export type InvestmentTypes = {
  buyToLet?: Maybe<Scalars['Boolean']>;
  renovateAndFlip?: Maybe<Scalars['Boolean']>;
  multiLet?: Maybe<Scalars['Boolean']>;
  privateEquity?: Maybe<Scalars['Boolean']>;
  notSure?: Maybe<Scalars['Boolean']>;
};

export type InvestmentTypesInput = {
  buyToLet?: Maybe<Scalars['Boolean']>;
  renovateAndFlip?: Maybe<Scalars['Boolean']>;
  multiLet?: Maybe<Scalars['Boolean']>;
  privateEquity?: Maybe<Scalars['Boolean']>;
  notSure?: Maybe<Scalars['Boolean']>;
};

/** The profile of an investor with their preferences */
export type InvestorProfile = {
  id: Scalars['GUID'];
  currency: Currency;
  /** @deprecated Use the more specific `investmentPreferencesCompleted`. */
  completed: Scalars['Boolean'];
  /** Whether the initial investment preferences questionnaire has been completed in full */
  investmentPreferencesCompleted: Scalars['Boolean'];
  /** Whether the wealth forecast has been completed in full */
  wealthForecastCompleted: Scalars['Boolean'];
  primaryObjective?: Maybe<PrimaryFinancialObjective>;
  leaveInheritance?: Maybe<Scalars['Boolean']>;
  /** Reason for signing up in the first place */
  whatBroughtYou?: Maybe<WhatBroughtYou>;
  whatBroughtYouDescription?: Maybe<Scalars['String']>;
  /** Level of experience where zero is none */
  experienceLevel?: Maybe<Scalars['Int']>;
  experienceLevelDescription?: Maybe<Scalars['String']>;
  grossMonthlySalary?: Maybe<Scalars['Int']>;
  investmentIncome?: Maybe<Scalars['Int']>;
  noMonthlyIncome?: Maybe<Scalars['Boolean']>;
  incomeOptOut?: Maybe<Scalars['Boolean']>;
  netMonthlyIncome?: Maybe<Scalars['Int']>;
  monthlyExpenses?: Maybe<Scalars['Int']>;
  monthlyIncomeToInvest?: Maybe<Scalars['Int']>;
  savingsToInvest?: Maybe<Scalars['Int']>;
  noSavingsToInvest?: Maybe<Scalars['Boolean']>;
  /** Whether the user requires Shariah compliant investments */
  shariah?: Maybe<Scalars['Boolean']>;
  /** Name of the person who referred this user if applicable */
  referrer?: Maybe<Scalars['String']>;
  /** Any general comment the user wants to make known about his investment preferences */
  comments?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  investmentTypes?: Maybe<InvestmentTypes>;
  /** @deprecated Field no longer supported */
  investmentFrequency?: Maybe<InvestmentFrequency>;
  /** @deprecated Field no longer supported */
  investmentFrequencyDescription?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  investmentFrequencySpecifics?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  investmentFrequencyPerYear?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  investmentFrequencyAmount?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  investmentPriorities?: Maybe<InvestmentPriorities>;
  /** @deprecated Field no longer supported */
  investmentPrioritiesDescription?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  riskAppetite?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  riskAppetiteDescription?: Maybe<Scalars['String']>;
};

/** Used to update an Investor Profile */
export type InvestorProfileInput = {
  id: Scalars['GUID'];
  /** Ignored, but allowed for convenience so the client needn't strip it out */
  investmentPreferencesCompleted?: Maybe<Scalars['Boolean']>;
  /** Ignored, but allowed for convenience so the client needn't strip it out */
  wealthForecastCompleted?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInput>;
  primaryObjective?: Maybe<PrimaryFinancialObjective>;
  leaveInheritance?: Maybe<Scalars['Boolean']>;
  whatBroughtYou?: Maybe<WhatBroughtYou>;
  experienceLevel?: Maybe<Scalars['Int']>;
  grossMonthlySalary?: Maybe<Scalars['Int']>;
  investmentIncome?: Maybe<Scalars['Int']>;
  noMonthlyIncome?: Maybe<Scalars['Boolean']>;
  incomeOptOut?: Maybe<Scalars['Boolean']>;
  netMonthlyIncome?: Maybe<Scalars['Int']>;
  monthlyExpenses?: Maybe<Scalars['Int']>;
  monthlyIncomeToInvest?: Maybe<Scalars['Int']>;
  savingsToInvest?: Maybe<Scalars['Int']>;
  noSavingsToInvest?: Maybe<Scalars['Boolean']>;
  shariah?: Maybe<Scalars['Boolean']>;
  referrer?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  investmentTypes?: Maybe<InvestmentTypesInput>;
  investmentFrequency?: Maybe<InvestmentFrequency>;
  investmentFrequencyPerYear?: Maybe<Scalars['Int']>;
  investmentFrequencyAmount?: Maybe<Scalars['Int']>;
  investmentPriorities?: Maybe<InvestmentPriorities>;
  riskAppetite?: Maybe<Scalars['Int']>;
};


export enum LeaseType {
  UnfurnishedLongStay = 'UnfurnishedLongStay',
  FurnishedLongStay = 'FurnishedLongStay',
  FurnishedShortStay = 'FurnishedShortStay',
  FurnishedShortStayGuarantee = 'FurnishedShortStayGuarantee'
}

export type MacroEconomics = {
  currentMonth: Scalars['Int'];
  anniversary: Scalars['Boolean'];
  costInflation: Scalars['Float'];
  primeLendingRate: Scalars['Float'];
};

export type MapBlock = {
  id: Scalars['ID'];
  contentType: ContentBlockType;
  center: Coordinate;
  delta: Coordinate;
  markers: Array<MapMarker>;
};

export type MapMarker = {
  coordinate: Coordinate;
  title: Scalars['String'];
  description: Scalars['String'];
};

export type Money = {
  currency: Scalars['String'];
  amount: Scalars['Float'];
};

export type MoneyInput = {
  currency: Scalars['String'];
  amount: Scalars['Float'];
};

export type MultiLetFinancials = {
  opportunityType: OpportunityType;
  currency: Currency;
  price: Scalars['Int'];
  priceAvailable: Scalars['Int'];
  totalCommitment: Scalars['Int'];
  writtenCommitment: Scalars['Int'];
  minimumInvestment: Scalars['Int'];
  rentAmount: Scalars['Int'];
  rentEscalation: Scalars['Float'];
  grossYield: Scalars['Float'];
  levies: Scalars['Int'];
  taxes: Scalars['Int'];
  managementCommission: Scalars['Float'];
  managementAmount: Scalars['Int'];
  maintenanceProvision: Scalars['Int'];
  insurancePremium: Scalars['Int'];
  cleaningServices: Scalars['Int'];
  electricityCharges: Scalars['Int'];
  securityServices: Scalars['Int'];
  auditFees: Scalars['Int'];
  netRevenue: Scalars['Int'];
  netYield: Scalars['Float'];
  monthlyCashFlow: Scalars['Int'];
  corporateIncomeTax: Scalars['Int'];
  dividend: Scalars['Int'];
  dividendYield: Scalars['Float'];
  initialInvestment: Scalars['Int'];
  holdPeriod: Scalars['Int'];
  irr: Scalars['Float'];
  moneyBackReturn: Scalars['Float'];
};

export type Mutation = {
  _empty?: Maybe<Scalars['String']>;
  declineOpportunity: OpportunityDecisionResponse;
  notificationPreferencesUpdate: NotificationPreferencesResponse;
  opportunityUpdateContent: OpportunityUpdateContentResponse;
  opportunityUpdateUnitStatus: OpportunityUpdateUnitStatusResponse;
  proceedWithOpportunity: OpportunityDecisionResponse;
  registerPushToken: RegisterPushTokenResponse;
  saveBlogPost: SaveBlogPostResponse;
  savePushCampaign: SavePushCampaignResponse;
  sendPushCampaign: SendPushCampaignResponse;
  userProfileUpdate: UserProfileResponse;
};


export type MutationDeclineOpportunityArgs = {
  input: DeclineOpportunityInput;
};


export type MutationNotificationPreferencesUpdateArgs = {
  input: UpdateNotificationPreferencesInput;
};


export type MutationOpportunityUpdateContentArgs = {
  input: OpportunityEditableContentInput;
};


export type MutationOpportunityUpdateUnitStatusArgs = {
  input: OpportunityUnitInput;
};


export type MutationProceedWithOpportunityArgs = {
  input: ProceedWithOpportunityInput;
};


export type MutationRegisterPushTokenArgs = {
  input: PushTokenInput;
};


export type MutationSaveBlogPostArgs = {
  input: BlogPostInput;
};


export type MutationSavePushCampaignArgs = {
  input: PushCampaignInput;
};


export type MutationSendPushCampaignArgs = {
  id: Scalars['GUID'];
  segment: Scalars['String'];
};


export type MutationUserProfileUpdateArgs = {
  input: UpdateUserProfileInput;
};

export type MyUserProfile = {
  user: User;
  investorProfile: InvestorProfile;
  wealthForecast: WealthForecast;
};

export type NotificationPreferencesResponse = {
  message: Scalars['String'];
  user: User;
};

export type OpportunitiesFilter = {
  statuses?: Maybe<Array<Scalars['String']>>;
};

export type Opportunity = {
  id: Scalars['GUID'];
  opportunityType: OpportunityType;
  ownershipType: OwnershipType;
  name: Scalars['String'];
  title: Scalars['String'];
  subTitle: Scalars['String'];
  opportunities: Array<Opportunity>;
  opportunitiesAvailable?: Maybe<Scalars['Int']>;
  /** @deprecated Use imageCarousel */
  images: Array<Scalars['String']>;
  imageCarousel: Array<Image>;
  address: Address;
  badges: Array<Badge>;
  specs: ResidentialPropertySpecs;
  content: OpportunityContent;
  financials: OpportunityFinancials;
  projections: Array<BuyToLetPeriod>;
  growthComparison: Array<RateComparison>;
  dividendComparison?: Maybe<Array<RateComparison>>;
  placesOfInterest: Array<PlaceOfInterest>;
  similarSalesAverage?: Maybe<SimilarAverages>;
  similarSales: Array<SimilarSale>;
  similarRentalsAverage?: Maybe<SimilarAverages>;
  similarRentals: Array<SimilarRental>;
  documents: Array<Document>;
  availableFrom?: Maybe<Scalars['Date']>;
  status: Scalars['String'];
  editableContent: Array<OpportunityEditableContent>;
  units: Array<OpportunityUnit>;
};

export type OpportunityContent = {
  overview: Array<ContentBlock>;
  financials: Array<ContentBlock>;
  suburb: Array<ContentBlock>;
};

export type OpportunityDecisionResponse = {
  opportunity: Opportunity;
  message: Scalars['String'];
};

/** A dynamic content record of an opportunity */
export type OpportunityEditableContent = {
  id: Scalars['GUID'];
  section: Scalars['String'];
  content: Array<ContentBlock>;
};

export type OpportunityEditableContentInput = {
  id: Scalars['GUID'];
  /** Ignored in updates, but used to flesh out the response */
  section: Scalars['String'];
  content: Scalars['JSON'];
};

export type OpportunityFinancials = BuyToLetFinancials | MultiLetFinancials | PrivateEquityFinancials;

export type OpportunityOptions = {
  leaseType?: Maybe<LeaseType>;
};

export enum OpportunitySaleStatus {
  Available = 'available',
  Reserved = 'reserved',
  Sold = 'sold'
}

export enum OpportunityType {
  BuyToLet = 'buyToLet',
  MultiLet = 'multiLet',
  PrivateEquity = 'privateEquity'
}

export type OpportunityUnit = {
  id: Scalars['GUID'];
  unitType?: Maybe<Scalars['Int']>;
  unitNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  status?: Maybe<OpportunitySaleStatus>;
  floorSize?: Maybe<Quantity>;
  purchasePrice?: Maybe<Scalars['Int']>;
  marketValue?: Maybe<Scalars['Int']>;
  rentAmount?: Maybe<Scalars['Int']>;
  rentExpires?: Maybe<Scalars['Date']>;
  levies?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  leviesConcessionYears?: Maybe<Scalars['Int']>;
  monthlyCashFlow?: Maybe<Scalars['Int']>;
  grossYield?: Maybe<Scalars['Float']>;
  netYield?: Maybe<Scalars['Float']>;
  irr?: Maybe<Scalars['Float']>;
  moneyBackReturn?: Maybe<Scalars['Float']>;
  published: Scalars['Boolean'];
};

export type OpportunityUnitInput = {
  id: Scalars['GUID'];
  unitType?: Maybe<Scalars['Int']>;
  unitNumber?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  status?: Maybe<OpportunitySaleStatus>;
  floorSize?: Maybe<QuantityInput>;
  purchasePrice?: Maybe<Scalars['Int']>;
  marketValue?: Maybe<Scalars['Int']>;
  rentAmount?: Maybe<Scalars['Int']>;
  rentExpires?: Maybe<Scalars['Date']>;
  levies?: Maybe<Scalars['Float']>;
  taxes?: Maybe<Scalars['Float']>;
  leviesConcessionYears?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type OpportunityUpdateContentResponse = {
  content: OpportunityEditableContent;
  message: Scalars['String'];
};

export type OpportunityUpdateUnitStatusResponse = {
  unit: OpportunityUnit;
  message: Scalars['String'];
};

export enum OwnershipType {
  SectionalTitle = 'SectionalTitle',
  FullTitle = 'FullTitle',
  LeaseHold = 'LeaseHold',
  ShareInSpv = 'ShareInSPV'
}

export type PhoneNumber = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type PhoneNumberInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type PlaceOfInterest = {
  name: Scalars['String'];
  type: Scalars['String'];
  distance: Scalars['Int'];
};

export enum PrimaryFinancialObjective {
  LongTermWealth = 'longTermWealth',
  PassiveIncome = 'passiveIncome',
  NotSure = 'notSure'
}

export type PrivateEquityFinancials = {
  opportunityType: OpportunityType;
  currency: Currency;
  price: Scalars['Int'];
  priceAvailable: Scalars['Int'];
  minimumInvestment: Scalars['Int'];
  rentAmount: Scalars['Int'];
  rentEscalation: Scalars['Float'];
  grossYield: Scalars['Float'];
  levies: Scalars['Int'];
  taxes: Scalars['Int'];
  managementCommission: Scalars['Float'];
  managementAmount: Scalars['Int'];
  maintenanceProvision: Scalars['Int'];
  insurancePremium: Scalars['Int'];
  netRevenue: Scalars['Int'];
  netYield: Scalars['Float'];
  monthlyCashFlow: Scalars['Int'];
  corporateIncomeTax: Scalars['Int'];
  dividend: Scalars['Int'];
  dividendYield: Scalars['Float'];
  initialInvestment: Scalars['Int'];
  holdPeriod: Scalars['Int'];
  irr: Scalars['Float'];
  moneyBackReturn: Scalars['Float'];
};

export type ProceedWithOpportunityInput = {
  id: Scalars['GUID'];
  nextStep?: Maybe<Scalars['String']>;
  unitTypeIndex?: Maybe<Scalars['Int']>;
  unitNumber?: Maybe<Scalars['String']>;
  investmentAmount?: Maybe<Scalars['Int']>;
  addonServices?: Maybe<Array<Scalars['String']>>;
  comments?: Maybe<Scalars['String']>;
};

export type PropertyCostRow = {
  slug: Scalars['String'];
  amount: Scalars['Float'];
  dayRate?: Maybe<Scalars['Float']>;
};

export type PropertySale = {
  date: Scalars['Date'];
  price: Scalars['Int'];
  pricePerSqm?: Maybe<Scalars['Int']>;
  growth?: Maybe<Scalars['Float']>;
  period?: Maybe<Scalars['Int']>;
};

export enum PublicationStatus {
  Draft = 'Draft',
  Preview = 'Preview',
  Published = 'Published',
  Archived = 'Archived'
}

export type PushCampaign = {
  id: Scalars['GUID'];
  name: Scalars['String'];
  title: Scalars['String'];
  /** iOS only */
  subTitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  deepLinkType: DeepLinkType;
  deepLinkUrl: Scalars['String'];
  /** Android only */
  channelId: Scalars['String'];
  sent?: Maybe<Scalars['DateTime']>;
  updated: Scalars['DateTime'];
  stats: PushCampaignStats;
  notifications: Array<PushCampaignNotification>;
};

export type PushCampaignInput = {
  id?: Maybe<Scalars['GUID']>;
  name: Scalars['String'];
  title: Scalars['String'];
  /** iOS only */
  subTitle?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  deepLinkType: DeepLinkType;
  deepLinkUrl: Scalars['String'];
  /** Android only */
  channelId: Scalars['String'];
};

export type PushCampaignNotification = {
  id: Scalars['ID'];
  queued: Scalars['DateTime'];
  scheduled?: Maybe<Scalars['DateTime']>;
  sent?: Maybe<Scalars['DateTime']>;
  delivered?: Maybe<Scalars['Boolean']>;
  opened?: Maybe<Scalars['DateTime']>;
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  user: User;
};

export type PushCampaignStats = {
  queued: Scalars['Int'];
  sent: Scalars['Int'];
  delivered: Scalars['Int'];
  opened: Scalars['Int'];
  error: Scalars['Int'];
  pending: Scalars['Int'];
};

export enum PushNotificationChannelId {
  InvestmentOpportunities = 'investmentOpportunities',
  GeneralInfo = 'generalInfo',
  Important = 'important',
  DirectMessages = 'directMessages'
}

export type PushTokenInput = {
  expoPushToken: Scalars['String'];
  devicePushToken: Scalars['String'];
  deviceOS: DeviceOs;
};

export type Quantity = {
  amount: Scalars['Float'];
  unit: Scalars['String'];
};

export type QuantityInput = {
  amount: Scalars['Float'];
  unit: Scalars['String'];
};

export type Query = {
  _empty?: Maybe<Scalars['String']>;
  blogPost?: Maybe<BlogPost>;
  blogPosts: Array<BlogPost>;
  investor?: Maybe<UserProfile>;
  investors: Array<UserProfile>;
  me?: Maybe<MyUserProfile>;
  opportunities: Array<Opportunity>;
  opportunity: Opportunity;
  pushCampaign?: Maybe<PushCampaign>;
  pushCampaigns: Array<PushCampaign>;
  userOpportunities: Array<Opportunity>;
  userOpportunitiesDeclined: Array<Opportunity>;
};


export type QueryBlogPostArgs = {
  id: Scalars['GUID'];
};


export type QueryBlogPostsArgs = {
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryInvestorArgs = {
  id: Scalars['ID'];
};


export type QueryOpportunitiesArgs = {
  filter?: Maybe<OpportunitiesFilter>;
};


export type QueryOpportunityArgs = {
  id: Scalars['GUID'];
  options?: Maybe<OpportunityOptions>;
};


export type QueryPushCampaignArgs = {
  id: Scalars['GUID'];
};


export type QueryUserOpportunitiesArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryUserOpportunitiesDeclinedArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type RateComparison = {
  label: Scalars['String'];
  rate: Scalars['Float'];
};

export type RegisterPushTokenResponse = {
  message: Scalars['String'];
};

export type ResidentialPropertySpecs = {
  type?: Maybe<Scalars['String']>;
  landArea?: Maybe<Quantity>;
  floorSize?: Maybe<Quantity>;
  bedrooms: Scalars['Int'];
  bathrooms: Scalars['Int'];
  garages?: Maybe<Scalars['Int']>;
  openParkings?: Maybe<Scalars['Int']>;
  coveredParkings?: Maybe<Scalars['Int']>;
  features?: Maybe<Array<Scalars['String']>>;
};

export type Returns = {
  equityValue: Scalars['Int'];
  postTaxCashFlow: Scalars['Int'];
  reversalOfTaxPaid: Scalars['Int'];
  preTaxCashFlow: Scalars['Int'];
  equityValueRealised: Scalars['Int'];
  capitalGainsTaxPaid: Scalars['Int'];
  exitFixedCosts: Scalars['Int'];
  exitVariableCosts: Scalars['Int'];
  preTaxExitCosts: Scalars['Int'];
  exitCostsTaxRecovered: Scalars['Int'];
  postTaxCashFlowOnExit: Scalars['Int'];
  preTaxCashFlowOnExit: Scalars['Int'];
  grossYield: Scalars['Float'];
  netYield: Scalars['Float'];
  discretionaryNetYield: Scalars['Float'];
};

export type RichTextBlock = {
  id: Scalars['ID'];
  contentType: ContentBlockType;
  html: Scalars['String'];
};

export enum Role {
  User = 'user',
  Admin = 'admin',
  System = 'system'
}

export type SaveBlogPostResponse = {
  message: Scalars['String'];
  blogPost: BlogPost;
};

export type SavePushCampaignResponse = {
  message: Scalars['String'];
  pushCampaign: PushCampaign;
};

export type SendPushCampaignResponse = {
  message: Scalars['String'];
  segment: Scalars['String'];
  pushCampaign: PushCampaign;
};

export type SimilarAverages = {
  amount: Scalars['Int'];
  amountPerSqm?: Maybe<Scalars['Int']>;
};

export type SimilarRental = {
  address: Address;
  specs: ResidentialPropertySpecs;
  rent: Scalars['Int'];
};

export type SimilarSale = {
  address: Address;
  specs: ResidentialPropertySpecs;
  sale: PropertySale;
};


export type Timeline = {
  period: Scalars['Int'];
  date: Scalars['Date'];
};

export type UpdateNotificationPreferencesInput = {
  userId: Scalars['ID'];
  receiveNotifications?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserProfileInput = {
  user?: Maybe<UserInput>;
  investorProfile?: Maybe<InvestorProfileInput>;
};

export type User = {
  id: Scalars['ID'];
  roles: Array<Maybe<Role>>;
  registered: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  receiveNotifications?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  id: Scalars['ID'];
  user: User;
  investorProfile: InvestorProfile;
  wealthForecast: WealthForecast;
};


export type UserProfileWealthForecastArgs = {
  opportunityId?: Maybe<Scalars['GUID']>;
};

export type UserProfileResponse = {
  message: Scalars['String'];
  user: User;
  investorProfile: InvestorProfile;
  wealthForecast: WealthForecast;
};

export type Video = {
  id: Scalars['ID'];
  uri: Scalars['String'];
  mime: Scalars['String'];
  aspectRatio: Scalars['Float'];
  posterPublicId?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type VideoBlock = {
  id: Scalars['ID'];
  contentType: ContentBlockType;
  video: Video;
  caption?: Maybe<Scalars['String']>;
};

export type VirtualTourBlock = {
  id: Scalars['ID'];
  contentType: ContentBlockType;
  uri: Scalars['String'];
  title: Scalars['String'];
};

export type WealthForecast = {
  id: Scalars['ID'];
  propertiesQualifiedFor?: Maybe<Scalars['Int']>;
  firstBuy: WealthForecastFirstBuy;
};

export type WealthForecastFirstBuy = {
  propertiesAfford?: Maybe<Scalars['Int']>;
  financials: BuyToLetFinancials;
  projections: Array<BuyToLetPeriod>;
  growthComparison: Array<RateComparison>;
};

export enum WhatBroughtYou {
  Opportunities = 'opportunities',
  LearnMore = 'learnMore',
  Browsing = 'browsing'
}

export type BlogPostEditorPageQueryVariables = Exact<{
  id: Scalars['GUID'];
}>;


export type BlogPostEditorPageQuery = { blogPost?: Maybe<(
    Pick<BlogPost, 'abstract' | 'status'>
    & BlogArticleFragment
  )> };

export type SaveBlogPostMutationVariables = Exact<{
  input: BlogPostInput;
}>;


export type SaveBlogPostMutation = { saveBlogPost: { blogPost: (
      Pick<BlogPost, 'abstract' | 'status'>
      & BlogArticleFragment
    ) } };

export type BlogPostListItemFragment = (
  Pick<BlogPost, 'id' | 'date' | 'author' | 'title' | 'status'>
  & { featureImage: Pick<Image, 'id' | 'publicId' | 'width' | 'aspectRatio'> }
);

export type BlogPostListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogPostListPageQuery = { blogPosts: Array<BlogPostListItemFragment> };

export type InvestorsPageListQueryVariables = Exact<{ [key: string]: never; }>;


export type InvestorsPageListQuery = { investors: Array<{ user: InvestorTableUserFragment, investorProfile: (
      Pick<InvestorProfile, 'id'>
      & InvestorTableProfileFragment
    ) }> };

export type InvestorTableUserFragment = Pick<User, 'id' | 'registered' | 'displayName' | 'email' | 'phoneNo'>;

export type InvestorTableProfileFragment = (
  Pick<InvestorProfile, 'completed' | 'whatBroughtYouDescription' | 'experienceLevelDescription' | 'investmentFrequencyDescription' | 'investmentFrequencySpecifics' | 'investmentPrioritiesDescription' | 'riskAppetiteDescription' | 'shariah' | 'referrer' | 'comments'>
  & { investmentTypes?: Maybe<Pick<InvestmentTypes, 'buyToLet' | 'renovateAndFlip' | 'multiLet' | 'privateEquity' | 'notSure'>>, currency: Pick<Currency, 'currency' | 'symbol'> }
);

export type OpportunitiesPageListQueryVariables = Exact<{ [key: string]: never; }>;


export type OpportunitiesPageListQuery = { opportunities: Array<OpportunityPreviewFragment> };

export type OpportunityPageQueryVariables = Exact<{
  id: Scalars['GUID'];
}>;


export type OpportunityPageQuery = { opportunity: (
    { units: Array<UnitsTableFragment>, financials: { currency: Pick<Currency, 'currency'> } | { currency: Pick<Currency, 'currency'> } }
    & OpportunityPreviewFragment
  ) };

export type UpdateOpportunityUnitStatusMutationVariables = Exact<{
  input: OpportunityUnitInput;
}>;


export type UpdateOpportunityUnitStatusMutation = { opportunityUpdateUnitStatus: { unit: UnitsTableFragment } };

export type UnitsTableFragment = (
  Pick<OpportunityUnit, 'id' | 'unitType' | 'unitNumber' | 'floor' | 'status' | 'purchasePrice' | 'marketValue' | 'rentAmount' | 'rentExpires' | 'levies' | 'taxes' | 'leviesConcessionYears' | 'monthlyCashFlow' | 'grossYield' | 'netYield' | 'irr' | 'moneyBackReturn' | 'published'>
  & { floorSize?: Maybe<Pick<Quantity, 'amount' | 'unit'>> }
);

export type PushCampaignEditorFragment = (
  Pick<PushCampaign, 'id' | 'name' | 'title' | 'subTitle' | 'body' | 'deepLinkType' | 'deepLinkUrl' | 'channelId' | 'sent'>
  & PushCampaignPreviewFragment
);

export type PushCampaignEditorPageQueryVariables = Exact<{
  id: Scalars['GUID'];
}>;


export type PushCampaignEditorPageQuery = { pushCampaign?: Maybe<PushCampaignEditorFragment> };

export type SavePushCampaignMutationVariables = Exact<{
  input: PushCampaignInput;
}>;


export type SavePushCampaignMutation = { savePushCampaign: (
    Pick<SavePushCampaignResponse, 'message'>
    & { pushCampaign: PushCampaignEditorFragment }
  ) };

export type SendPushCampaignMutationVariables = Exact<{
  id: Scalars['GUID'];
  segment: Scalars['String'];
}>;


export type SendPushCampaignMutation = { sendPushCampaign: (
    Pick<SendPushCampaignResponse, 'message' | 'segment'>
    & { pushCampaign: PushCampaignEditorFragment }
  ) };

export type DeepLinkUrlOpportunityFormInputQueryVariables = Exact<{
  filter?: Maybe<OpportunitiesFilter>;
}>;


export type DeepLinkUrlOpportunityFormInputQuery = { opportunities: Array<Pick<Opportunity, 'id' | 'name'>> };

export type PushCampaignPreviewFragment = Pick<PushCampaign, 'title' | 'subTitle' | 'body'>;

export type PushCampaignListItemFragment = (
  Pick<PushCampaign, 'id' | 'name' | 'title' | 'channelId' | 'sent'>
  & { stats: Pick<PushCampaignStats, 'queued' | 'sent' | 'delivered' | 'opened' | 'error' | 'pending'> }
);

export type PushCampaignListPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PushCampaignListPageQuery = { pushCampaigns: Array<PushCampaignListItemFragment> };

export type PushNotificationListItemFragment = (
  Pick<PushCampaignNotification, 'id' | 'scheduled' | 'sent' | 'delivered' | 'opened' | 'errorCode' | 'errorMessage'>
  & { user: Pick<User, 'id' | 'displayName' | 'email' | 'phoneNo'> }
);

export type PushCampaignStatsPageQueryVariables = Exact<{
  id: Scalars['GUID'];
}>;


export type PushCampaignStatsPageQuery = { pushCampaign?: Maybe<(
    Pick<PushCampaign, 'id' | 'name'>
    & { stats: Pick<PushCampaignStats, 'queued' | 'sent' | 'delivered' | 'opened' | 'error' | 'pending'>, notifications: Array<PushNotificationListItemFragment> }
    & PushCampaignPreviewFragment
  )> };

export type DynamicContentImageFragment = Pick<Image, 'id' | 'publicId' | 'aspectRatio'>;

type DynamicContent_RichTextBlock_Fragment = Pick<RichTextBlock, 'id' | 'contentType' | 'html'>;

type DynamicContent_ImageBlock_Fragment = (
  Pick<ImageBlock, 'id' | 'contentType' | 'maxWidth' | 'caption'>
  & { image: DynamicContentImageFragment }
);

type DynamicContent_ImageGridBlock_Fragment = (
  Pick<ImageGridBlock, 'id' | 'contentType'>
  & { images: Array<DynamicContentImageFragment> }
);

type DynamicContent_ImageCarouselBlock_Fragment = (
  Pick<ImageCarouselBlock, 'id' | 'contentType' | 'caption'>
  & { images: Array<DynamicContentImageFragment> }
);

type DynamicContent_VideoBlock_Fragment = (
  Pick<VideoBlock, 'id' | 'contentType' | 'caption'>
  & { video: Pick<Video, 'id' | 'uri' | 'mime' | 'aspectRatio' | 'posterPublicId'> }
);

type DynamicContent_MapBlock_Fragment = (
  Pick<MapBlock, 'id' | 'contentType'>
  & { center: Pick<Coordinate, 'latitude' | 'longitude'>, delta: Pick<Coordinate, 'latitude' | 'longitude'>, markers: Array<(
    Pick<MapMarker, 'title' | 'description'>
    & { coordinate: Pick<Coordinate, 'latitude' | 'longitude'> }
  )> }
);

type DynamicContent_VirtualTourBlock_Fragment = Pick<VirtualTourBlock, 'id' | 'contentType' | 'title' | 'uri'>;

export type DynamicContentFragment = DynamicContent_RichTextBlock_Fragment | DynamicContent_ImageBlock_Fragment | DynamicContent_ImageGridBlock_Fragment | DynamicContent_ImageCarouselBlock_Fragment | DynamicContent_VideoBlock_Fragment | DynamicContent_MapBlock_Fragment | DynamicContent_VirtualTourBlock_Fragment;

export type BadgeDisplayFragment = Pick<Badge, 'title' | 'color'>;

export type BlogArticleFragment = (
  Pick<BlogPost, 'id' | 'date' | 'title' | 'author'>
  & { featureImage: Pick<Image, 'id' | 'publicId' | 'width' | 'aspectRatio'>, content: Array<DynamicContent_RichTextBlock_Fragment | DynamicContent_ImageBlock_Fragment | DynamicContent_ImageGridBlock_Fragment | DynamicContent_ImageCarouselBlock_Fragment | DynamicContent_VideoBlock_Fragment | DynamicContent_MapBlock_Fragment | DynamicContent_VirtualTourBlock_Fragment> }
);

export type BlogArticleListItemFragment = (
  Pick<BlogPost, 'id' | 'title' | 'author' | 'abstract'>
  & { featureImage: Pick<Image, 'id' | 'publicId' | 'width' | 'aspectRatio'> }
);

export type BlogArticlesFragment = BlogArticleListItemFragment;

export type AnnualReturnComparisonFragment = { growthComparison: Array<Pick<RateComparison, 'label' | 'rate'>>, financials: Pick<BuyToLetFinancials, 'irr'> | Pick<MultiLetFinancials, 'irr'> };

export type BuyToLetDiscretionaryCashFlowFragment = (
  Pick<BuyToLetFinancials, 'managementAmount' | 'managementCommission' | 'maintenanceProvision' | 'insurancePremium' | 'discretionaryMonthlyCashFlow'>
  & { currency: Pick<Currency, 'currency'> }
);

export type BuyToLetInitialCashInvestmentFragment = (
  Pick<BuyToLetFinancials, 'price' | 'securingDeposit' | 'bondRegistrationFee' | 'conveyancingFee' | 'transferDuty' | 'furniturePackage' | 'initialInvestment'>
  & { bond?: Maybe<Pick<BondSpec, 'ltv' | 'balance' | 'initiationFee' | 'initiationFeeCapitalised'>> }
);

export type BuyToLetMonthlyCashFlowFragment = (
  Pick<BuyToLetFinancials, 'rentDayRate' | 'occupancyRate' | 'rentAmount' | 'levies' | 'leviesConcession' | 'taxes' | 'revenue' | 'netRevenue' | 'monthlyCashFlow'>
  & { currency: Pick<Currency, 'currency'>, directCostRows: Array<Pick<DirectCostRow, 'slug' | 'percentage' | 'amount'>>, propertyCostRows: Array<Pick<PropertyCostRow, 'slug' | 'amount'>>, bond?: Maybe<Pick<BondSpec, 'term' | 'fixedRate' | 'relativeRate' | 'serviceFee' | 'repayment'>> }
);

export type ComparableRentalsFragment = { specs: (
    Pick<ResidentialPropertySpecs, 'bedrooms' | 'bathrooms'>
    & { floorSize?: Maybe<Pick<Quantity, 'amount' | 'unit'>> }
  ), financials: Pick<BuyToLetFinancials, 'rentAmount'>, similarRentalsAverage?: Maybe<Pick<SimilarAverages, 'amount'>>, similarRentals: Array<(
    Pick<SimilarRental, 'rent'>
    & { address: Pick<Address, 'premise' | 'unitNumber'>, specs: (
      Pick<ResidentialPropertySpecs, 'bedrooms' | 'bathrooms'>
      & { floorSize?: Maybe<Pick<Quantity, 'amount' | 'unit'>> }
    ) }
  )> };

export type ComparableSalesFragment = { specs: (
    Pick<ResidentialPropertySpecs, 'bedrooms' | 'bathrooms'>
    & { floorSize?: Maybe<Pick<Quantity, 'amount' | 'unit'>> }
  ), financials: Pick<BuyToLetFinancials, 'price' | 'pricePerSqm'>, similarSalesAverage?: Maybe<Pick<SimilarAverages, 'amount' | 'amountPerSqm'>>, similarSales: Array<{ address: Pick<Address, 'premise' | 'unitNumber'>, specs: (
      Pick<ResidentialPropertySpecs, 'bedrooms' | 'bathrooms'>
      & { floorSize?: Maybe<Pick<Quantity, 'amount' | 'unit'>> }
    ), sale: Pick<PropertySale, 'price' | 'pricePerSqm' | 'date' | 'growth' | 'period'> }> };

export type DividendYieldComparisonFragment = { dividendComparison?: Maybe<Array<Pick<RateComparison, 'label' | 'rate'>>>, financials: Pick<MultiLetFinancials, 'dividendYield'> };

export type MultiLetInitialCashInvestmentFragment = Pick<MultiLetFinancials, 'price' | 'initialInvestment'>;

export type MultiLetMonthlyCashFlowFragment = (
  Pick<MultiLetFinancials, 'rentAmount' | 'levies' | 'taxes' | 'netRevenue' | 'managementAmount' | 'managementCommission' | 'maintenanceProvision' | 'insurancePremium' | 'cleaningServices' | 'electricityCharges' | 'securityServices' | 'auditFees' | 'monthlyCashFlow'>
  & { currency: Pick<Currency, 'currency'> }
);

export type PrivateEquityMonthlyCashFlowFragment = (
  Pick<PrivateEquityFinancials, 'rentAmount' | 'levies' | 'taxes' | 'netRevenue' | 'managementAmount' | 'managementCommission' | 'maintenanceProvision' | 'insurancePremium' | 'monthlyCashFlow'>
  & { currency: Pick<Currency, 'currency'> }
);

export type PrivateEquityInvestmentFragment = (
  Pick<PrivateEquityFinancials, 'minimumInvestment'>
  & { currency: Pick<Currency, 'currency'> }
);

export type QuarterlyDividendMultiLetFragment = Pick<MultiLetFinancials, 'monthlyCashFlow' | 'corporateIncomeTax' | 'dividend'>;

export type QuarterlyDividendPrivateEquityFragment = Pick<PrivateEquityFinancials, 'monthlyCashFlow' | 'corporateIncomeTax' | 'dividend'>;

export type ReturnBuyToLetFragment = Pick<BuyToLetFinancials, 'holdPeriod' | 'moneyBackReturn' | 'irr'>;

export type ReturnMultiLetFragment = Pick<MultiLetFinancials, 'holdPeriod' | 'moneyBackReturn' | 'irr'>;

export type ReturnPrivateEquityFragment = Pick<PrivateEquityFinancials, 'holdPeriod' | 'moneyBackReturn' | 'irr'>;

export type YieldBuyToLetFragment = Pick<BuyToLetFinancials, 'grossYield' | 'netYield' | 'discretionaryNetYield'>;

export type YieldMultiLetFragment = Pick<MultiLetFinancials, 'grossYield' | 'netYield' | 'dividendYield'>;

export type YieldPrivateEquityFragment = Pick<PrivateEquityFinancials, 'grossYield' | 'netYield' | 'dividendYield'>;

export type ContactNumberFormFragment = Pick<User, 'phoneNo'>;

export type ExperienceLevelFormFragment = Pick<InvestorProfile, 'experienceLevel'>;

export type FinalCommentsFormFragment = Pick<InvestorProfile, 'referrer' | 'shariah' | 'comments'>;

export type FinancialObjectivesFormFragment = Pick<InvestorProfile, 'investmentPreferencesCompleted' | 'primaryObjective' | 'leaveInheritance'>;

export type WhatBroughtYouFormFragment = Pick<InvestorProfile, 'whatBroughtYou'>;

export type FirstPropertyFinancialsFragment = (
  { firstBuy: (
    Pick<WealthForecastFirstBuy, 'propertiesAfford'>
    & { financials: (
      Pick<BuyToLetFinancials, 'securingDeposit' | 'price' | 'marketValue' | 'discountPercent' | 'rentAmount' | 'netYield' | 'monthlyCashFlow' | 'irr'>
      & { currency: Pick<Currency, 'currency'>, bond?: Maybe<Pick<BondSpec, 'term'>> }
      & BuyToLetMonthlyCashFlowFragment
    ) }
  ) }
  & WealthForecastFinancialsFragment
);

export type FirstPropertyProjectionFragment = (
  { firstBuy: (
    Pick<WealthForecastFirstBuy, 'propertiesAfford'>
    & { financials: (
      Pick<BuyToLetFinancials, 'rentAmount' | 'irr'>
      & { currency: Pick<Currency, 'currency'>, bond?: Maybe<Pick<BondSpec, 'term'>> }
    ), growthComparison: Array<Pick<RateComparison, 'label' | 'rate'>> }
  ) }
  & WealthForecastFinancialsFragment
);

export type PropertiesQualifiedFragment = Pick<WealthForecast, 'propertiesQualifiedFor'>;

export type WealthForecastFinancialsFragment = { firstBuy: (
    Pick<WealthForecastFirstBuy, 'propertiesAfford'>
    & { financials: Pick<BuyToLetFinancials, 'monthlyCashFlow' | 'initialInvestment'>, projections: Array<{ timeline: Pick<Timeline, 'date'>, bondRepayment: Pick<BondRepayments, 'balance'>, cashFlows: Pick<CashFlows, 'monthlyCashFlow'>, returns: Pick<Returns, 'equityValue'> }> }
  ) };

export type InvestmentBudgetFormFragment = (
  Pick<InvestorProfile, 'monthlyIncomeToInvest' | 'savingsToInvest' | 'noSavingsToInvest'>
  & { currency: Pick<Currency, 'currency' | 'symbol'> }
);

export type MonthlyIncomeGrossFormFragment = (
  Pick<InvestorProfile, 'grossMonthlySalary' | 'investmentIncome' | 'noMonthlyIncome'>
  & { currency: Pick<Currency, 'currency' | 'symbol'> }
);

export type MonthlyIncomeNetFormFragment = (
  Pick<InvestorProfile, 'netMonthlyIncome' | 'monthlyExpenses'>
  & { currency: Pick<Currency, 'currency' | 'symbol'> }
);

export type OpportunityPreviewFragment = (
  Pick<Opportunity, 'id' | 'opportunityType' | 'title' | 'subTitle' | 'opportunitiesAvailable'>
  & { imageCarousel: Array<Pick<Image, 'id' | 'publicId' | 'aspectRatio'>>, badges: Array<BadgeDisplayFragment>, financials: (
    { __typename: 'BuyToLetFinancials' }
    & Pick<BuyToLetFinancials, 'opportunityType' | 'price' | 'monthlyCashFlow' | 'netYield' | 'irr'>
    & { currency: Pick<Currency, 'currency'> }
  ) | (
    { __typename: 'MultiLetFinancials' }
    & Pick<MultiLetFinancials, 'opportunityType' | 'price' | 'priceAvailable' | 'totalCommitment' | 'minimumInvestment' | 'monthlyCashFlow' | 'netYield' | 'dividendYield' | 'irr'>
    & { currency: Pick<Currency, 'currency'> }
  ) | { __typename: 'PrivateEquityFinancials' } }
);

export type BuyToLetSummaryFragment = (
  Pick<BuyToLetFinancials, 'price' | 'marketValue' | 'discount' | 'discountPercent' | 'rentAmount' | 'netYield' | 'monthlyCashFlow' | 'irr'>
  & { currency: Pick<Currency, 'currency'> }
);

export type BuyToLetSummaryRangesFragment = (
  Pick<BuyToLetFinancials, 'leaseType' | 'leaseTypes' | 'discountPercent' | 'rentAmount' | 'netYield' | 'monthlyCashFlow' | 'irr'>
  & { currency: Pick<Currency, 'currency'> }
);

export type MultiLetSummaryFragment = (
  Pick<MultiLetFinancials, 'price' | 'totalCommitment' | 'minimumInvestment' | 'rentAmount' | 'dividend' | 'netYield' | 'monthlyCashFlow' | 'irr'>
  & { currency: Pick<Currency, 'currency'> }
);

export type PrivateEquitySummaryFragment = (
  Pick<MultiLetFinancials, 'price' | 'priceAvailable' | 'minimumInvestment' | 'rentAmount' | 'dividend' | 'netYield' | 'monthlyCashFlow' | 'irr'>
  & { currency: Pick<Currency, 'currency'> }
);

export type OpportunityTabFinancialsWithContentFragment = (
  Pick<Opportunity, 'opportunityType'>
  & { content: { financials: Array<DynamicContent_RichTextBlock_Fragment | DynamicContent_ImageBlock_Fragment | DynamicContent_ImageGridBlock_Fragment | DynamicContent_ImageCarouselBlock_Fragment | DynamicContent_VideoBlock_Fragment | DynamicContent_MapBlock_Fragment | DynamicContent_VirtualTourBlock_Fragment> } }
  & BuyToLetOpportunityFinancialsFragment
  & MultiLetOpportunityFinancialsFragment
  & PrivateEquityOpportunityFinancialsFragment
  & AnnualReturnComparisonFragment
  & DividendYieldComparisonFragment
  & ComparableSalesFragment
  & ComparableRentalsFragment
);

export type OpportunityTabFinancialsFragment = (
  Pick<Opportunity, 'opportunityType'>
  & BuyToLetOpportunityFinancialsFragment
  & MultiLetOpportunityFinancialsFragment
  & PrivateEquityOpportunityFinancialsFragment
  & AnnualReturnComparisonFragment
  & DividendYieldComparisonFragment
  & ComparableSalesFragment
  & ComparableRentalsFragment
);

export type BuyToLetOpportunityFinancialsFragment = (
  Pick<Opportunity, 'ownershipType'>
  & { financials: (
    Pick<BuyToLetFinancials, 'leaseType' | 'leaseTypes'>
    & BuyToLetMonthlyCashFlowFragment
    & BuyToLetDiscretionaryCashFlowFragment
    & BuyToLetInitialCashInvestmentFragment
    & YieldBuyToLetFragment
    & ReturnBuyToLetFragment
  ) }
  & UnitTypePickerFragment
  & AnnualReturnComparisonFragment
  & ComparableSalesFragment
  & ComparableRentalsFragment
);

export type MultiLetOpportunityFinancialsFragment = (
  { financials: (
    MultiLetMonthlyCashFlowFragment
    & MultiLetInitialCashInvestmentFragment
    & QuarterlyDividendMultiLetFragment
    & YieldMultiLetFragment
    & ReturnMultiLetFragment
  ) }
  & AnnualReturnComparisonFragment
  & DividendYieldComparisonFragment
);

export type PrivateEquityOpportunityFinancialsFragment = (
  { financials: (
    PrivateEquityMonthlyCashFlowFragment
    & PrivateEquityInvestmentFragment
    & QuarterlyDividendPrivateEquityFragment
    & YieldPrivateEquityFragment
    & ReturnPrivateEquityFragment
  ) }
  & AnnualReturnComparisonFragment
  & DividendYieldComparisonFragment
);

export type UnitTypePickerFragment = { opportunities: Array<(
    Pick<Opportunity, 'title' | 'subTitle'>
    & { specs: { floorSize?: Maybe<Pick<Quantity, 'amount' | 'unit'>> }, financials: (
      Pick<BuyToLetFinancials, 'price'>
      & { currency: Pick<Currency, 'currency'> }
    ) }
  )> };

export type OpportunityTabOverviewFragment = (
  Pick<Opportunity, 'title' | 'subTitle' | 'opportunityType' | 'opportunitiesAvailable'>
  & { badges: Array<BadgeDisplayFragment>, specs: PropertyMainFeaturesFragment, content: { overview: Array<DynamicContent_RichTextBlock_Fragment | DynamicContent_ImageBlock_Fragment | DynamicContent_ImageGridBlock_Fragment | DynamicContent_ImageCarouselBlock_Fragment | DynamicContent_VideoBlock_Fragment | DynamicContent_MapBlock_Fragment | DynamicContent_VirtualTourBlock_Fragment> }, documents: Array<Pick<Document, 'name' | 'mime' | 'uri'>>, financials: (
    BuyToLetSummaryFragment
    & BuyToLetSummaryRangesFragment
  ) | (
    MultiLetSummaryFragment
    & PrivateEquitySummaryFragment
  ), opportunities: Array<{ financials: BuyToLetSummaryRangesFragment }> }
);

export type OpportunityTabSuburbFragment = { content: { suburb: Array<DynamicContent_RichTextBlock_Fragment | DynamicContent_ImageBlock_Fragment | DynamicContent_ImageGridBlock_Fragment | DynamicContent_ImageCarouselBlock_Fragment | DynamicContent_VideoBlock_Fragment | DynamicContent_MapBlock_Fragment | DynamicContent_VirtualTourBlock_Fragment> }, placesOfInterest: Array<Pick<PlaceOfInterest, 'name' | 'type' | 'distance'>> };

export type PropertyMainFeaturesFragment = (
  Pick<ResidentialPropertySpecs, 'bedrooms' | 'bathrooms' | 'garages' | 'openParkings' | 'coveredParkings'>
  & { landArea?: Maybe<Pick<Quantity, 'amount' | 'unit'>>, floorSize?: Maybe<Pick<Quantity, 'amount' | 'unit'>> }
);

export const BlogPostListItemFragmentDoc = gql`
    fragment BlogPostListItem on BlogPost {
  id
  date
  author
  title
  status
  featureImage {
    id
    publicId
    width
    aspectRatio
  }
}
    `;
export const InvestorTableUserFragmentDoc = gql`
    fragment InvestorTableUser on User {
  id
  registered
  displayName
  email
  phoneNo
}
    `;
export const InvestorTableProfileFragmentDoc = gql`
    fragment InvestorTableProfile on InvestorProfile {
  completed
  whatBroughtYouDescription
  experienceLevelDescription
  investmentTypes {
    buyToLet
    renovateAndFlip
    multiLet
    privateEquity
    notSure
  }
  investmentFrequencyDescription
  investmentFrequencySpecifics
  investmentPrioritiesDescription
  riskAppetiteDescription
  shariah
  referrer
  comments
  currency {
    currency
    symbol
  }
}
    `;
export const UnitsTableFragmentDoc = gql`
    fragment UnitsTable on OpportunityUnit {
  id
  unitType
  unitNumber
  floor
  status
  floorSize {
    amount
    unit
  }
  purchasePrice
  marketValue
  rentAmount
  rentExpires
  levies
  taxes
  leviesConcessionYears
  monthlyCashFlow
  grossYield
  netYield
  irr
  moneyBackReturn
  published
}
    `;
export const PushCampaignPreviewFragmentDoc = gql`
    fragment PushCampaignPreview on PushCampaign {
  title
  subTitle
  body
}
    `;
export const PushCampaignEditorFragmentDoc = gql`
    fragment PushCampaignEditor on PushCampaign {
  id
  name
  title
  subTitle
  body
  deepLinkType
  deepLinkUrl
  channelId
  sent
  ...PushCampaignPreview
}
    ${PushCampaignPreviewFragmentDoc}`;
export const PushCampaignListItemFragmentDoc = gql`
    fragment PushCampaignListItem on PushCampaign {
  id
  name
  title
  channelId
  sent
  stats {
    queued
    sent
    delivered
    opened
    error
    pending
  }
}
    `;
export const PushNotificationListItemFragmentDoc = gql`
    fragment PushNotificationListItem on PushCampaignNotification {
  id
  scheduled
  sent
  delivered
  opened
  errorCode
  errorMessage
  user {
    id
    displayName
    email
    phoneNo
  }
}
    `;
export const DynamicContentImageFragmentDoc = gql`
    fragment DynamicContentImage on Image {
  id
  publicId
  aspectRatio
}
    `;
export const DynamicContentFragmentDoc = gql`
    fragment DynamicContent on ContentBlock {
  ... on RichTextBlock {
    id
    contentType
    html
  }
  ... on ImageBlock {
    id
    contentType
    image {
      ...DynamicContentImage
    }
    maxWidth
    caption
  }
  ... on ImageGridBlock {
    id
    contentType
    images {
      ...DynamicContentImage
    }
  }
  ... on ImageCarouselBlock {
    id
    contentType
    images {
      ...DynamicContentImage
    }
    caption
  }
  ... on VideoBlock {
    id
    contentType
    caption
    video {
      id
      uri
      mime
      aspectRatio
      posterPublicId
    }
  }
  ... on MapBlock {
    id
    contentType
    center {
      latitude
      longitude
    }
    delta {
      latitude
      longitude
    }
    markers {
      coordinate {
        latitude
        longitude
      }
      title
      description
    }
  }
  ... on VirtualTourBlock {
    id
    contentType
    title
    uri
  }
}
    ${DynamicContentImageFragmentDoc}`;
export const BlogArticleFragmentDoc = gql`
    fragment BlogArticle on BlogPost {
  id
  date
  title
  author
  featureImage {
    id
    publicId
    width
    aspectRatio
  }
  content {
    ...DynamicContent
  }
}
    ${DynamicContentFragmentDoc}`;
export const BlogArticleListItemFragmentDoc = gql`
    fragment BlogArticleListItem on BlogPost {
  id
  title
  author
  abstract
  featureImage {
    id
    publicId
    width
    aspectRatio
  }
}
    `;
export const BlogArticlesFragmentDoc = gql`
    fragment BlogArticles on BlogPost {
  ...BlogArticleListItem
}
    ${BlogArticleListItemFragmentDoc}`;
export const ContactNumberFormFragmentDoc = gql`
    fragment ContactNumberForm on User {
  phoneNo
}
    `;
export const ExperienceLevelFormFragmentDoc = gql`
    fragment ExperienceLevelForm on InvestorProfile {
  experienceLevel
}
    `;
export const FinalCommentsFormFragmentDoc = gql`
    fragment FinalCommentsForm on InvestorProfile {
  referrer
  shariah
  comments
}
    `;
export const FinancialObjectivesFormFragmentDoc = gql`
    fragment FinancialObjectivesForm on InvestorProfile {
  investmentPreferencesCompleted
  primaryObjective
  leaveInheritance
}
    `;
export const WhatBroughtYouFormFragmentDoc = gql`
    fragment WhatBroughtYouForm on InvestorProfile {
  whatBroughtYou
}
    `;
export const WealthForecastFinancialsFragmentDoc = gql`
    fragment WealthForecastFinancials on WealthForecast {
  firstBuy {
    propertiesAfford
    financials {
      monthlyCashFlow
      initialInvestment
    }
    projections {
      timeline {
        date
      }
      bondRepayment {
        balance
      }
      cashFlows {
        monthlyCashFlow
      }
      returns {
        equityValue
      }
    }
  }
}
    `;
export const BuyToLetMonthlyCashFlowFragmentDoc = gql`
    fragment BuyToLetMonthlyCashFlow on BuyToLetFinancials {
  currency {
    currency
  }
  rentDayRate
  occupancyRate
  rentAmount
  levies
  leviesConcession
  taxes
  directCostRows {
    slug
    percentage
    amount
  }
  revenue
  propertyCostRows {
    slug
    amount
  }
  netRevenue
  bond {
    term
    fixedRate
    relativeRate
    serviceFee
    repayment
  }
  monthlyCashFlow
}
    `;
export const FirstPropertyFinancialsFragmentDoc = gql`
    fragment FirstPropertyFinancials on WealthForecast {
  ...WealthForecastFinancials
  firstBuy {
    propertiesAfford
    financials {
      currency {
        currency
      }
      securingDeposit
      price
      marketValue
      discountPercent
      rentAmount
      netYield
      monthlyCashFlow
      irr
      bond {
        term
      }
      rentAmount
      ...BuyToLetMonthlyCashFlow
    }
  }
}
    ${WealthForecastFinancialsFragmentDoc}
${BuyToLetMonthlyCashFlowFragmentDoc}`;
export const FirstPropertyProjectionFragmentDoc = gql`
    fragment FirstPropertyProjection on WealthForecast {
  ...WealthForecastFinancials
  firstBuy {
    propertiesAfford
    financials {
      currency {
        currency
      }
      rentAmount
      bond {
        term
      }
      irr
    }
    growthComparison {
      label
      rate
    }
  }
}
    ${WealthForecastFinancialsFragmentDoc}`;
export const PropertiesQualifiedFragmentDoc = gql`
    fragment PropertiesQualified on WealthForecast {
  propertiesQualifiedFor
}
    `;
export const InvestmentBudgetFormFragmentDoc = gql`
    fragment InvestmentBudgetForm on InvestorProfile {
  currency {
    currency
    symbol
  }
  monthlyIncomeToInvest
  savingsToInvest
  noSavingsToInvest
}
    `;
export const MonthlyIncomeGrossFormFragmentDoc = gql`
    fragment MonthlyIncomeGrossForm on InvestorProfile {
  currency {
    currency
    symbol
  }
  grossMonthlySalary
  investmentIncome
  noMonthlyIncome
}
    `;
export const MonthlyIncomeNetFormFragmentDoc = gql`
    fragment MonthlyIncomeNetForm on InvestorProfile {
  currency {
    currency
    symbol
  }
  netMonthlyIncome
  monthlyExpenses
}
    `;
export const BadgeDisplayFragmentDoc = gql`
    fragment BadgeDisplay on Badge {
  title
  color
}
    `;
export const OpportunityPreviewFragmentDoc = gql`
    fragment OpportunityPreview on Opportunity {
  id
  opportunityType
  title
  subTitle
  opportunitiesAvailable
  imageCarousel {
    id
    publicId
    aspectRatio
  }
  badges {
    ...BadgeDisplay
  }
  financials {
    __typename
    ... on BuyToLetFinancials {
      opportunityType
      currency {
        currency
      }
      price
      monthlyCashFlow
      netYield
      irr
    }
    ... on MultiLetFinancials {
      opportunityType
      currency {
        currency
      }
      price
      priceAvailable
      totalCommitment
      minimumInvestment
      monthlyCashFlow
      netYield
      dividendYield
      irr
    }
  }
}
    ${BadgeDisplayFragmentDoc}`;
export const UnitTypePickerFragmentDoc = gql`
    fragment UnitTypePicker on Opportunity {
  opportunities {
    title
    subTitle
    specs {
      floorSize {
        amount
        unit
      }
    }
    financials {
      ... on BuyToLetFinancials {
        currency {
          currency
        }
        price
      }
    }
  }
}
    `;
export const BuyToLetDiscretionaryCashFlowFragmentDoc = gql`
    fragment BuyToLetDiscretionaryCashFlow on BuyToLetFinancials {
  currency {
    currency
  }
  managementAmount
  managementCommission
  maintenanceProvision
  insurancePremium
  discretionaryMonthlyCashFlow
}
    `;
export const BuyToLetInitialCashInvestmentFragmentDoc = gql`
    fragment BuyToLetInitialCashInvestment on BuyToLetFinancials {
  price
  securingDeposit
  bondRegistrationFee
  conveyancingFee
  transferDuty
  furniturePackage
  initialInvestment
  bond {
    ltv
    balance
    initiationFee
    initiationFeeCapitalised
  }
}
    `;
export const YieldBuyToLetFragmentDoc = gql`
    fragment YieldBuyToLet on BuyToLetFinancials {
  grossYield
  netYield
  discretionaryNetYield
}
    `;
export const ReturnBuyToLetFragmentDoc = gql`
    fragment ReturnBuyToLet on BuyToLetFinancials {
  holdPeriod
  moneyBackReturn
  irr
}
    `;
export const AnnualReturnComparisonFragmentDoc = gql`
    fragment AnnualReturnComparison on Opportunity {
  growthComparison {
    label
    rate
  }
  financials {
    ... on BuyToLetFinancials {
      irr
    }
    ... on MultiLetFinancials {
      irr
    }
  }
}
    `;
export const ComparableSalesFragmentDoc = gql`
    fragment ComparableSales on Opportunity {
  specs {
    bedrooms
    bathrooms
    floorSize {
      amount
      unit
    }
  }
  financials {
    ... on BuyToLetFinancials {
      price
      pricePerSqm
    }
  }
  similarSalesAverage {
    amount
    amountPerSqm
  }
  similarSales {
    address {
      premise
      unitNumber
    }
    specs {
      bedrooms
      bathrooms
      floorSize {
        amount
        unit
      }
    }
    sale {
      price
      pricePerSqm
      date
      growth
      period
    }
  }
}
    `;
export const ComparableRentalsFragmentDoc = gql`
    fragment ComparableRentals on Opportunity {
  specs {
    bedrooms
    bathrooms
    floorSize {
      amount
      unit
    }
  }
  financials {
    ... on BuyToLetFinancials {
      rentAmount
    }
  }
  similarRentalsAverage {
    amount
  }
  similarRentals {
    address {
      premise
      unitNumber
    }
    specs {
      bedrooms
      bathrooms
      floorSize {
        amount
        unit
      }
    }
    rent
  }
}
    `;
export const BuyToLetOpportunityFinancialsFragmentDoc = gql`
    fragment BuyToLetOpportunityFinancials on Opportunity {
  ...UnitTypePicker
  ownershipType
  financials {
    ... on BuyToLetFinancials {
      leaseType
      leaseTypes
      ...BuyToLetMonthlyCashFlow
      ...BuyToLetDiscretionaryCashFlow
      ...BuyToLetInitialCashInvestment
      ...YieldBuyToLet
      ...ReturnBuyToLet
    }
  }
  ...AnnualReturnComparison
  ...ComparableSales
  ...ComparableRentals
}
    ${UnitTypePickerFragmentDoc}
${BuyToLetMonthlyCashFlowFragmentDoc}
${BuyToLetDiscretionaryCashFlowFragmentDoc}
${BuyToLetInitialCashInvestmentFragmentDoc}
${YieldBuyToLetFragmentDoc}
${ReturnBuyToLetFragmentDoc}
${AnnualReturnComparisonFragmentDoc}
${ComparableSalesFragmentDoc}
${ComparableRentalsFragmentDoc}`;
export const MultiLetMonthlyCashFlowFragmentDoc = gql`
    fragment MultiLetMonthlyCashFlow on MultiLetFinancials {
  currency {
    currency
  }
  rentAmount
  levies
  taxes
  netRevenue
  managementAmount
  managementCommission
  maintenanceProvision
  insurancePremium
  cleaningServices
  electricityCharges
  securityServices
  auditFees
  monthlyCashFlow
}
    `;
export const MultiLetInitialCashInvestmentFragmentDoc = gql`
    fragment MultiLetInitialCashInvestment on MultiLetFinancials {
  price
  initialInvestment
}
    `;
export const QuarterlyDividendMultiLetFragmentDoc = gql`
    fragment QuarterlyDividendMultiLet on MultiLetFinancials {
  monthlyCashFlow
  corporateIncomeTax
  dividend
}
    `;
export const YieldMultiLetFragmentDoc = gql`
    fragment YieldMultiLet on MultiLetFinancials {
  grossYield
  netYield
  dividendYield
}
    `;
export const ReturnMultiLetFragmentDoc = gql`
    fragment ReturnMultiLet on MultiLetFinancials {
  holdPeriod
  moneyBackReturn
  irr
}
    `;
export const DividendYieldComparisonFragmentDoc = gql`
    fragment DividendYieldComparison on Opportunity {
  dividendComparison {
    label
    rate
  }
  financials {
    ... on MultiLetFinancials {
      dividendYield
    }
  }
}
    `;
export const MultiLetOpportunityFinancialsFragmentDoc = gql`
    fragment MultiLetOpportunityFinancials on Opportunity {
  financials {
    ... on MultiLetFinancials {
      ...MultiLetMonthlyCashFlow
      ...MultiLetInitialCashInvestment
      ...QuarterlyDividendMultiLet
      ...YieldMultiLet
      ...ReturnMultiLet
    }
  }
  ...AnnualReturnComparison
  ...DividendYieldComparison
}
    ${MultiLetMonthlyCashFlowFragmentDoc}
${MultiLetInitialCashInvestmentFragmentDoc}
${QuarterlyDividendMultiLetFragmentDoc}
${YieldMultiLetFragmentDoc}
${ReturnMultiLetFragmentDoc}
${AnnualReturnComparisonFragmentDoc}
${DividendYieldComparisonFragmentDoc}`;
export const PrivateEquityMonthlyCashFlowFragmentDoc = gql`
    fragment PrivateEquityMonthlyCashFlow on PrivateEquityFinancials {
  currency {
    currency
  }
  rentAmount
  levies
  taxes
  netRevenue
  managementAmount
  managementCommission
  maintenanceProvision
  insurancePremium
  monthlyCashFlow
}
    `;
export const PrivateEquityInvestmentFragmentDoc = gql`
    fragment PrivateEquityInvestment on PrivateEquityFinancials {
  currency {
    currency
  }
  minimumInvestment
}
    `;
export const QuarterlyDividendPrivateEquityFragmentDoc = gql`
    fragment QuarterlyDividendPrivateEquity on PrivateEquityFinancials {
  monthlyCashFlow
  corporateIncomeTax
  dividend
}
    `;
export const YieldPrivateEquityFragmentDoc = gql`
    fragment YieldPrivateEquity on PrivateEquityFinancials {
  grossYield
  netYield
  dividendYield
}
    `;
export const ReturnPrivateEquityFragmentDoc = gql`
    fragment ReturnPrivateEquity on PrivateEquityFinancials {
  holdPeriod
  moneyBackReturn
  irr
}
    `;
export const PrivateEquityOpportunityFinancialsFragmentDoc = gql`
    fragment PrivateEquityOpportunityFinancials on Opportunity {
  financials {
    ... on PrivateEquityFinancials {
      ...PrivateEquityMonthlyCashFlow
      ...PrivateEquityInvestment
      ...QuarterlyDividendPrivateEquity
      ...YieldPrivateEquity
      ...ReturnPrivateEquity
    }
  }
  ...AnnualReturnComparison
  ...DividendYieldComparison
}
    ${PrivateEquityMonthlyCashFlowFragmentDoc}
${PrivateEquityInvestmentFragmentDoc}
${QuarterlyDividendPrivateEquityFragmentDoc}
${YieldPrivateEquityFragmentDoc}
${ReturnPrivateEquityFragmentDoc}
${AnnualReturnComparisonFragmentDoc}
${DividendYieldComparisonFragmentDoc}`;
export const OpportunityTabFinancialsWithContentFragmentDoc = gql`
    fragment OpportunityTabFinancialsWithContent on Opportunity {
  opportunityType
  content {
    financials {
      ...DynamicContent
    }
  }
  ...BuyToLetOpportunityFinancials
  ...MultiLetOpportunityFinancials
  ...PrivateEquityOpportunityFinancials
  ...AnnualReturnComparison
  ...DividendYieldComparison
  ...ComparableSales
  ...ComparableRentals
}
    ${DynamicContentFragmentDoc}
${BuyToLetOpportunityFinancialsFragmentDoc}
${MultiLetOpportunityFinancialsFragmentDoc}
${PrivateEquityOpportunityFinancialsFragmentDoc}
${AnnualReturnComparisonFragmentDoc}
${DividendYieldComparisonFragmentDoc}
${ComparableSalesFragmentDoc}
${ComparableRentalsFragmentDoc}`;
export const OpportunityTabFinancialsFragmentDoc = gql`
    fragment OpportunityTabFinancials on Opportunity {
  opportunityType
  ...BuyToLetOpportunityFinancials
  ...MultiLetOpportunityFinancials
  ...PrivateEquityOpportunityFinancials
  ...AnnualReturnComparison
  ...DividendYieldComparison
  ...ComparableSales
  ...ComparableRentals
}
    ${BuyToLetOpportunityFinancialsFragmentDoc}
${MultiLetOpportunityFinancialsFragmentDoc}
${PrivateEquityOpportunityFinancialsFragmentDoc}
${AnnualReturnComparisonFragmentDoc}
${DividendYieldComparisonFragmentDoc}
${ComparableSalesFragmentDoc}
${ComparableRentalsFragmentDoc}`;
export const PropertyMainFeaturesFragmentDoc = gql`
    fragment PropertyMainFeatures on ResidentialPropertySpecs {
  landArea {
    amount
    unit
  }
  floorSize {
    amount
    unit
  }
  bedrooms
  bathrooms
  garages
  openParkings
  coveredParkings
}
    `;
export const BuyToLetSummaryFragmentDoc = gql`
    fragment BuyToLetSummary on BuyToLetFinancials {
  currency {
    currency
  }
  price
  marketValue
  discount
  discountPercent
  rentAmount
  netYield
  monthlyCashFlow
  irr
}
    `;
export const BuyToLetSummaryRangesFragmentDoc = gql`
    fragment BuyToLetSummaryRanges on BuyToLetFinancials {
  currency {
    currency
  }
  leaseType
  leaseTypes
  discountPercent
  rentAmount
  netYield
  monthlyCashFlow
  irr
}
    `;
export const MultiLetSummaryFragmentDoc = gql`
    fragment MultiLetSummary on MultiLetFinancials {
  currency {
    currency
  }
  price
  totalCommitment
  minimumInvestment
  rentAmount
  dividend
  netYield
  monthlyCashFlow
  irr
}
    `;
export const PrivateEquitySummaryFragmentDoc = gql`
    fragment PrivateEquitySummary on MultiLetFinancials {
  currency {
    currency
  }
  price
  priceAvailable
  minimumInvestment
  rentAmount
  dividend
  netYield
  monthlyCashFlow
  irr
}
    `;
export const OpportunityTabOverviewFragmentDoc = gql`
    fragment OpportunityTabOverview on Opportunity {
  title
  subTitle
  opportunityType
  opportunitiesAvailable
  badges {
    ...BadgeDisplay
  }
  specs {
    ...PropertyMainFeatures
  }
  content {
    overview {
      ...DynamicContent
    }
  }
  documents {
    name
    mime
    uri
  }
  financials {
    ...BuyToLetSummary
    ...BuyToLetSummaryRanges
    ...MultiLetSummary
    ...PrivateEquitySummary
  }
  opportunities {
    financials {
      ...BuyToLetSummaryRanges
    }
  }
}
    ${BadgeDisplayFragmentDoc}
${PropertyMainFeaturesFragmentDoc}
${DynamicContentFragmentDoc}
${BuyToLetSummaryFragmentDoc}
${BuyToLetSummaryRangesFragmentDoc}
${MultiLetSummaryFragmentDoc}
${PrivateEquitySummaryFragmentDoc}`;
export const OpportunityTabSuburbFragmentDoc = gql`
    fragment OpportunityTabSuburb on Opportunity {
  content {
    suburb {
      ...DynamicContent
    }
  }
  placesOfInterest {
    name
    type
    distance
  }
}
    ${DynamicContentFragmentDoc}`;
export const BlogPostEditorPageDocument = gql`
    query BlogPostEditorPage($id: GUID!) {
  blogPost(id: $id) {
    abstract
    status
    ...BlogArticle
  }
}
    ${BlogArticleFragmentDoc}`;

/**
 * __useBlogPostEditorPageQuery__
 *
 * To run a query within a React component, call `useBlogPostEditorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostEditorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostEditorPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlogPostEditorPageQuery(baseOptions: Apollo.QueryHookOptions<BlogPostEditorPageQuery, BlogPostEditorPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogPostEditorPageQuery, BlogPostEditorPageQueryVariables>(BlogPostEditorPageDocument, options);
      }
export function useBlogPostEditorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogPostEditorPageQuery, BlogPostEditorPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogPostEditorPageQuery, BlogPostEditorPageQueryVariables>(BlogPostEditorPageDocument, options);
        }
export type BlogPostEditorPageQueryHookResult = ReturnType<typeof useBlogPostEditorPageQuery>;
export type BlogPostEditorPageLazyQueryHookResult = ReturnType<typeof useBlogPostEditorPageLazyQuery>;
export type BlogPostEditorPageQueryResult = Apollo.QueryResult<BlogPostEditorPageQuery, BlogPostEditorPageQueryVariables>;
export const SaveBlogPostDocument = gql`
    mutation SaveBlogPost($input: BlogPostInput!) {
  saveBlogPost(input: $input) {
    blogPost {
      abstract
      status
      ...BlogArticle
    }
  }
}
    ${BlogArticleFragmentDoc}`;
export type SaveBlogPostMutationFn = Apollo.MutationFunction<SaveBlogPostMutation, SaveBlogPostMutationVariables>;

/**
 * __useSaveBlogPostMutation__
 *
 * To run a mutation, you first call `useSaveBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBlogPostMutation, { data, loading, error }] = useSaveBlogPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveBlogPostMutation(baseOptions?: Apollo.MutationHookOptions<SaveBlogPostMutation, SaveBlogPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBlogPostMutation, SaveBlogPostMutationVariables>(SaveBlogPostDocument, options);
      }
export type SaveBlogPostMutationHookResult = ReturnType<typeof useSaveBlogPostMutation>;
export type SaveBlogPostMutationResult = Apollo.MutationResult<SaveBlogPostMutation>;
export type SaveBlogPostMutationOptions = Apollo.BaseMutationOptions<SaveBlogPostMutation, SaveBlogPostMutationVariables>;
export const BlogPostListPageDocument = gql`
    query BlogPostListPage {
  blogPosts(includeUnpublished: true) {
    ...BlogPostListItem
  }
}
    ${BlogPostListItemFragmentDoc}`;

/**
 * __useBlogPostListPageQuery__
 *
 * To run a query within a React component, call `useBlogPostListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogPostListPageQuery(baseOptions?: Apollo.QueryHookOptions<BlogPostListPageQuery, BlogPostListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogPostListPageQuery, BlogPostListPageQueryVariables>(BlogPostListPageDocument, options);
      }
export function useBlogPostListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogPostListPageQuery, BlogPostListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogPostListPageQuery, BlogPostListPageQueryVariables>(BlogPostListPageDocument, options);
        }
export type BlogPostListPageQueryHookResult = ReturnType<typeof useBlogPostListPageQuery>;
export type BlogPostListPageLazyQueryHookResult = ReturnType<typeof useBlogPostListPageLazyQuery>;
export type BlogPostListPageQueryResult = Apollo.QueryResult<BlogPostListPageQuery, BlogPostListPageQueryVariables>;
export const InvestorsPageListDocument = gql`
    query InvestorsPageList {
  investors {
    user {
      ...InvestorTableUser
    }
    investorProfile {
      id
      ...InvestorTableProfile
    }
  }
}
    ${InvestorTableUserFragmentDoc}
${InvestorTableProfileFragmentDoc}`;

/**
 * __useInvestorsPageListQuery__
 *
 * To run a query within a React component, call `useInvestorsPageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestorsPageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestorsPageListQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestorsPageListQuery(baseOptions?: Apollo.QueryHookOptions<InvestorsPageListQuery, InvestorsPageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestorsPageListQuery, InvestorsPageListQueryVariables>(InvestorsPageListDocument, options);
      }
export function useInvestorsPageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestorsPageListQuery, InvestorsPageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestorsPageListQuery, InvestorsPageListQueryVariables>(InvestorsPageListDocument, options);
        }
export type InvestorsPageListQueryHookResult = ReturnType<typeof useInvestorsPageListQuery>;
export type InvestorsPageListLazyQueryHookResult = ReturnType<typeof useInvestorsPageListLazyQuery>;
export type InvestorsPageListQueryResult = Apollo.QueryResult<InvestorsPageListQuery, InvestorsPageListQueryVariables>;
export const OpportunitiesPageListDocument = gql`
    query OpportunitiesPageList {
  opportunities {
    ...OpportunityPreview
  }
}
    ${OpportunityPreviewFragmentDoc}`;

/**
 * __useOpportunitiesPageListQuery__
 *
 * To run a query within a React component, call `useOpportunitiesPageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunitiesPageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunitiesPageListQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpportunitiesPageListQuery(baseOptions?: Apollo.QueryHookOptions<OpportunitiesPageListQuery, OpportunitiesPageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpportunitiesPageListQuery, OpportunitiesPageListQueryVariables>(OpportunitiesPageListDocument, options);
      }
export function useOpportunitiesPageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpportunitiesPageListQuery, OpportunitiesPageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpportunitiesPageListQuery, OpportunitiesPageListQueryVariables>(OpportunitiesPageListDocument, options);
        }
export type OpportunitiesPageListQueryHookResult = ReturnType<typeof useOpportunitiesPageListQuery>;
export type OpportunitiesPageListLazyQueryHookResult = ReturnType<typeof useOpportunitiesPageListLazyQuery>;
export type OpportunitiesPageListQueryResult = Apollo.QueryResult<OpportunitiesPageListQuery, OpportunitiesPageListQueryVariables>;
export const OpportunityPageDocument = gql`
    query OpportunityPage($id: GUID!) {
  opportunity(id: $id) {
    ...OpportunityPreview
    units {
      ...UnitsTable
    }
    financials {
      ... on BuyToLetFinancials {
        currency {
          currency
        }
      }
      ... on MultiLetFinancials {
        currency {
          currency
        }
      }
    }
  }
}
    ${OpportunityPreviewFragmentDoc}
${UnitsTableFragmentDoc}`;

/**
 * __useOpportunityPageQuery__
 *
 * To run a query within a React component, call `useOpportunityPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityPageQuery(baseOptions: Apollo.QueryHookOptions<OpportunityPageQuery, OpportunityPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpportunityPageQuery, OpportunityPageQueryVariables>(OpportunityPageDocument, options);
      }
export function useOpportunityPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpportunityPageQuery, OpportunityPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpportunityPageQuery, OpportunityPageQueryVariables>(OpportunityPageDocument, options);
        }
export type OpportunityPageQueryHookResult = ReturnType<typeof useOpportunityPageQuery>;
export type OpportunityPageLazyQueryHookResult = ReturnType<typeof useOpportunityPageLazyQuery>;
export type OpportunityPageQueryResult = Apollo.QueryResult<OpportunityPageQuery, OpportunityPageQueryVariables>;
export const UpdateOpportunityUnitStatusDocument = gql`
    mutation UpdateOpportunityUnitStatus($input: OpportunityUnitInput!) {
  opportunityUpdateUnitStatus(input: $input) {
    unit {
      ...UnitsTable
    }
  }
}
    ${UnitsTableFragmentDoc}`;
export type UpdateOpportunityUnitStatusMutationFn = Apollo.MutationFunction<UpdateOpportunityUnitStatusMutation, UpdateOpportunityUnitStatusMutationVariables>;

/**
 * __useUpdateOpportunityUnitStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOpportunityUnitStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpportunityUnitStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpportunityUnitStatusMutation, { data, loading, error }] = useUpdateOpportunityUnitStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpportunityUnitStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOpportunityUnitStatusMutation, UpdateOpportunityUnitStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOpportunityUnitStatusMutation, UpdateOpportunityUnitStatusMutationVariables>(UpdateOpportunityUnitStatusDocument, options);
      }
export type UpdateOpportunityUnitStatusMutationHookResult = ReturnType<typeof useUpdateOpportunityUnitStatusMutation>;
export type UpdateOpportunityUnitStatusMutationResult = Apollo.MutationResult<UpdateOpportunityUnitStatusMutation>;
export type UpdateOpportunityUnitStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOpportunityUnitStatusMutation, UpdateOpportunityUnitStatusMutationVariables>;
export const PushCampaignEditorPageDocument = gql`
    query PushCampaignEditorPage($id: GUID!) {
  pushCampaign(id: $id) {
    ...PushCampaignEditor
  }
}
    ${PushCampaignEditorFragmentDoc}`;

/**
 * __usePushCampaignEditorPageQuery__
 *
 * To run a query within a React component, call `usePushCampaignEditorPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePushCampaignEditorPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushCampaignEditorPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePushCampaignEditorPageQuery(baseOptions: Apollo.QueryHookOptions<PushCampaignEditorPageQuery, PushCampaignEditorPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PushCampaignEditorPageQuery, PushCampaignEditorPageQueryVariables>(PushCampaignEditorPageDocument, options);
      }
export function usePushCampaignEditorPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PushCampaignEditorPageQuery, PushCampaignEditorPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PushCampaignEditorPageQuery, PushCampaignEditorPageQueryVariables>(PushCampaignEditorPageDocument, options);
        }
export type PushCampaignEditorPageQueryHookResult = ReturnType<typeof usePushCampaignEditorPageQuery>;
export type PushCampaignEditorPageLazyQueryHookResult = ReturnType<typeof usePushCampaignEditorPageLazyQuery>;
export type PushCampaignEditorPageQueryResult = Apollo.QueryResult<PushCampaignEditorPageQuery, PushCampaignEditorPageQueryVariables>;
export const SavePushCampaignDocument = gql`
    mutation SavePushCampaign($input: PushCampaignInput!) {
  savePushCampaign(input: $input) {
    message
    pushCampaign {
      ...PushCampaignEditor
    }
  }
}
    ${PushCampaignEditorFragmentDoc}`;
export type SavePushCampaignMutationFn = Apollo.MutationFunction<SavePushCampaignMutation, SavePushCampaignMutationVariables>;

/**
 * __useSavePushCampaignMutation__
 *
 * To run a mutation, you first call `useSavePushCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePushCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePushCampaignMutation, { data, loading, error }] = useSavePushCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePushCampaignMutation(baseOptions?: Apollo.MutationHookOptions<SavePushCampaignMutation, SavePushCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePushCampaignMutation, SavePushCampaignMutationVariables>(SavePushCampaignDocument, options);
      }
export type SavePushCampaignMutationHookResult = ReturnType<typeof useSavePushCampaignMutation>;
export type SavePushCampaignMutationResult = Apollo.MutationResult<SavePushCampaignMutation>;
export type SavePushCampaignMutationOptions = Apollo.BaseMutationOptions<SavePushCampaignMutation, SavePushCampaignMutationVariables>;
export const SendPushCampaignDocument = gql`
    mutation SendPushCampaign($id: GUID!, $segment: String!) {
  sendPushCampaign(id: $id, segment: $segment) {
    message
    segment
    pushCampaign {
      ...PushCampaignEditor
    }
  }
}
    ${PushCampaignEditorFragmentDoc}`;
export type SendPushCampaignMutationFn = Apollo.MutationFunction<SendPushCampaignMutation, SendPushCampaignMutationVariables>;

/**
 * __useSendPushCampaignMutation__
 *
 * To run a mutation, you first call `useSendPushCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPushCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPushCampaignMutation, { data, loading, error }] = useSendPushCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      segment: // value for 'segment'
 *   },
 * });
 */
export function useSendPushCampaignMutation(baseOptions?: Apollo.MutationHookOptions<SendPushCampaignMutation, SendPushCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPushCampaignMutation, SendPushCampaignMutationVariables>(SendPushCampaignDocument, options);
      }
export type SendPushCampaignMutationHookResult = ReturnType<typeof useSendPushCampaignMutation>;
export type SendPushCampaignMutationResult = Apollo.MutationResult<SendPushCampaignMutation>;
export type SendPushCampaignMutationOptions = Apollo.BaseMutationOptions<SendPushCampaignMutation, SendPushCampaignMutationVariables>;
export const DeepLinkUrlOpportunityFormInputDocument = gql`
    query DeepLinkUrlOpportunityFormInput($filter: OpportunitiesFilter) {
  opportunities(filter: $filter) {
    id
    name
  }
}
    `;

/**
 * __useDeepLinkUrlOpportunityFormInputQuery__
 *
 * To run a query within a React component, call `useDeepLinkUrlOpportunityFormInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeepLinkUrlOpportunityFormInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeepLinkUrlOpportunityFormInputQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDeepLinkUrlOpportunityFormInputQuery(baseOptions?: Apollo.QueryHookOptions<DeepLinkUrlOpportunityFormInputQuery, DeepLinkUrlOpportunityFormInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeepLinkUrlOpportunityFormInputQuery, DeepLinkUrlOpportunityFormInputQueryVariables>(DeepLinkUrlOpportunityFormInputDocument, options);
      }
export function useDeepLinkUrlOpportunityFormInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeepLinkUrlOpportunityFormInputQuery, DeepLinkUrlOpportunityFormInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeepLinkUrlOpportunityFormInputQuery, DeepLinkUrlOpportunityFormInputQueryVariables>(DeepLinkUrlOpportunityFormInputDocument, options);
        }
export type DeepLinkUrlOpportunityFormInputQueryHookResult = ReturnType<typeof useDeepLinkUrlOpportunityFormInputQuery>;
export type DeepLinkUrlOpportunityFormInputLazyQueryHookResult = ReturnType<typeof useDeepLinkUrlOpportunityFormInputLazyQuery>;
export type DeepLinkUrlOpportunityFormInputQueryResult = Apollo.QueryResult<DeepLinkUrlOpportunityFormInputQuery, DeepLinkUrlOpportunityFormInputQueryVariables>;
export const PushCampaignListPageDocument = gql`
    query PushCampaignListPage {
  pushCampaigns {
    ...PushCampaignListItem
  }
}
    ${PushCampaignListItemFragmentDoc}`;

/**
 * __usePushCampaignListPageQuery__
 *
 * To run a query within a React component, call `usePushCampaignListPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePushCampaignListPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushCampaignListPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePushCampaignListPageQuery(baseOptions?: Apollo.QueryHookOptions<PushCampaignListPageQuery, PushCampaignListPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PushCampaignListPageQuery, PushCampaignListPageQueryVariables>(PushCampaignListPageDocument, options);
      }
export function usePushCampaignListPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PushCampaignListPageQuery, PushCampaignListPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PushCampaignListPageQuery, PushCampaignListPageQueryVariables>(PushCampaignListPageDocument, options);
        }
export type PushCampaignListPageQueryHookResult = ReturnType<typeof usePushCampaignListPageQuery>;
export type PushCampaignListPageLazyQueryHookResult = ReturnType<typeof usePushCampaignListPageLazyQuery>;
export type PushCampaignListPageQueryResult = Apollo.QueryResult<PushCampaignListPageQuery, PushCampaignListPageQueryVariables>;
export const PushCampaignStatsPageDocument = gql`
    query PushCampaignStatsPage($id: GUID!) {
  pushCampaign(id: $id) {
    id
    name
    ...PushCampaignPreview
    stats {
      queued
      sent
      delivered
      opened
      error
      pending
    }
    notifications {
      ...PushNotificationListItem
    }
  }
}
    ${PushCampaignPreviewFragmentDoc}
${PushNotificationListItemFragmentDoc}`;

/**
 * __usePushCampaignStatsPageQuery__
 *
 * To run a query within a React component, call `usePushCampaignStatsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePushCampaignStatsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePushCampaignStatsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePushCampaignStatsPageQuery(baseOptions: Apollo.QueryHookOptions<PushCampaignStatsPageQuery, PushCampaignStatsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PushCampaignStatsPageQuery, PushCampaignStatsPageQueryVariables>(PushCampaignStatsPageDocument, options);
      }
export function usePushCampaignStatsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PushCampaignStatsPageQuery, PushCampaignStatsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PushCampaignStatsPageQuery, PushCampaignStatsPageQueryVariables>(PushCampaignStatsPageDocument, options);
        }
export type PushCampaignStatsPageQueryHookResult = ReturnType<typeof usePushCampaignStatsPageQuery>;
export type PushCampaignStatsPageLazyQueryHookResult = ReturnType<typeof usePushCampaignStatsPageLazyQuery>;
export type PushCampaignStatsPageQueryResult = Apollo.QueryResult<PushCampaignStatsPageQuery, PushCampaignStatsPageQueryVariables>;