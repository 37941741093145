import { useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import { Button, ButtonText, FormError, FormInput } from '@common/components'
import { onSubmissionError } from '@common/final-form'
import { SignInValues, useAuth } from '../../hooks/useAuth/useAuth'
import { AuthPageOutline } from './components/AuthPageOutline'

export const SignIn = () => {
  const { signIn } = useAuth()
  const passwordField = useRef<HTMLInputElement>()

  const onSubmit = (values: SignInValues, form: FormApi<SignInValues>) =>
    signIn(values).then(
      onSubmissionError(() => {
        passwordField.current && passwordField.current.focus()
        setTimeout(() => {
          form.change('password', '')
          form.resetFieldState('password')
        })
      }),
    )

  return (
    <AuthPageOutline>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <>
            <Field
              name="email"
              render={(fieldProps) => (
                <FormInput
                  {...fieldProps}
                  placeholder="Email"
                  textContentType="username"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  onSubmitEditing={() => passwordField.current?.focus()}
                  blurOnSubmit={false}
                  returnKeyType="next"
                />
              )}
            />
            <Field
              name="password"
              render={(fieldProps) => (
                <FormInput
                  {...fieldProps}
                  placeholder="Password"
                  textContentType="password"
                  autoCapitalize="none"
                  secureTextEntry
                  onSubmitEditing={handleSubmit}
                  returnKeyType="go"
                  ref={passwordField}
                />
              )}
            />

            <FormError />

            <Button
              block
              variant="primary"
              btnSize="large"
              onPress={handleSubmit}
              disabled={submitting}
            >
              <ButtonText>{submitting ? 'Signing in…' : 'Sign in'}</ButtonText>
            </Button>
          </>
        )}
      </Form>
    </AuthPageOutline>
  )
}
