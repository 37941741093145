import { useField } from 'react-final-form'
import { ContentBlockType } from '../../../../graphql'
import { ImageContent } from './ImageContent/ImageContent'
import { RichTextContent } from './RichTextContent/RichTextContent'

// import { ImageCarouselContent } from './ImageCarouselContent/ImageCarouselContent'
// import { ImageGridContent } from './ImageGridContent/ImageGridContent'
// import { MapContent } from './MapContent/MapContent'
// import { VideoContent } from './VideoContent/VideoContent'
// import { VirtualTourContent } from './VirtualTourContent/VirtualTourContent'

type Props = {
  fieldName: string
  width: number
}

export const ContentEditor = ({ fieldName, width }: Props) => {
  const {
    input: { value: contentType },
  } = useField(`${fieldName}.contentType`)

  switch (contentType) {
    case ContentBlockType.RichTextBlock:
      return <RichTextContent fieldName={fieldName} />
    case ContentBlockType.ImageBlock:
      return <ImageContent fieldName={fieldName} width={width} />
    // case ContentBlockType.ImageGridBlock:
    //   return <ImageGridContent fieldName={fieldName} width={width} />
    // case ContentBlockType.ImageCarouselBlock:
    //   return <ImageCarouselContent fieldName={fieldName} width={width} />
    // case ContentBlockType.MapBlock:
    //   return <MapContent fieldName={fieldName} width={width} />
    // case ContentBlockType.VirtualTourBlock:
    //   return <VirtualTourContent fieldName={fieldName} width={width} />
    // case ContentBlockType.VideoBlock:
    //   return <VideoContent fieldName={fieldName} width={width} />
    default:
      return null
  }
}
