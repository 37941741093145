import { Field, Form, useFormState } from 'react-final-form'
import { Prompt, useRouteMatch } from 'react-router-dom'
import arrayMutators from 'final-form-arrays'
import { noop } from 'lodash'
import { runFormMutation } from '@common/apollo-client'
import {
  Box,
  Button,
  ButtonText,
  FormInput,
  FullScreenLoader,
  ImagePreviewProvider,
  PickerField,
} from '@common/components'
import { BlogArticle as Post, BlogArticleListItem as ListItem } from '@common/domain'
import { MediaDimensionsProvider } from '@common/media'
import { FormImageUploader, Page } from '../../../../components'
import { DynamicContentEditor } from '../../../../components/content/DynamicContentEditor/DynamicContentEditor'
import { FormCollapsibleSection } from '../../../../components/forms/FormCollapsibleSection/FormCollapsibleSection'
import { Content } from '../../../../components/mobile/Content/Content'
import {
  BlogPost,
  BlogPostEditorPageDocument,
  PublicationStatus,
  SaveBlogPostMutation,
  SaveBlogPostMutationVariables,
  useBlogPostEditorPageQuery,
  useSaveBlogPostMutation,
} from '../../../../graphql'

const publicationStatusOptions =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(PublicationStatus).map((k) => ({ label: PublicationStatus[k as any] }))

const BlogListItemPreview = () => {
  const formState = useFormState<BlogPost>({ subscription: { values: true } })
  return <ListItem {...formState.values} onPress={noop} />
}

const BlogPostPreview = () => {
  const formState = useFormState<BlogPost>({ subscription: { values: true } })
  return <Post {...formState.values} />
}

const formWidth = 427
const previewWidth = 375

export const BlogPostEditorPage = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const { loading, data } = useBlogPostEditorPageQuery({ variables: { id } })

  const [saveBlogPost] = useSaveBlogPostMutation({ ignoreResults: true })

  if (loading || !data?.blogPost) {
    return <FullScreenLoader />
  }

  const postDetailsExpanded = data.blogPost.content.length <= 1

  return (
    <Page>
      <ImagePreviewProvider>
        <Form
          // @ts-ignore 3rd party library type mismatch
          mutators={arrayMutators}
          initialValues={data.blogPost}
          onSubmit={({ featureImage, content, ...blogPost }) =>
            runFormMutation<
              SaveBlogPostMutation,
              SaveBlogPostMutationVariables['input'],
              SaveBlogPostMutationVariables
            >(saveBlogPost, {
              ...blogPost,
              content: JSON.stringify(content),
            })
          }
          subscription={{ dirty: true }}
        >
          {({ handleSubmit, dirty }) => (
            <Box width={980} flexDirection="row" justifyContent="space-between" mx="auto">
              <Box width={previewWidth}>
                <MediaDimensionsProvider width={previewWidth}>
                  <Box
                    borderWidth={1}
                    borderColor="light4"
                    borderRadius="medium"
                    height={92}
                    mb="medium"
                    p="xxsmall"
                  >
                    <BlogListItemPreview />
                  </Box>
                </MediaDimensionsProvider>

                <Box borderWidth={1} borderColor="light4" borderRadius="medium" height={700}>
                  <MediaDimensionsProvider width={previewWidth} height={700}>
                    <Content>
                      <BlogPostPreview />
                    </Content>
                  </MediaDimensionsProvider>
                </Box>
              </Box>

              <Box width={formWidth}>
                <MediaDimensionsProvider width={formWidth} height={700}>
                  <Content padding="none">
                    <FormCollapsibleSection
                      title="Blog post details"
                      initiallyExpanded={postDetailsExpanded}
                    >
                      <Field name="title" placeholder="Title" component={FormInput} />
                      <Field name="author" placeholder="Author" component={FormInput} />
                      <Field
                        name="date"
                        placeholder="Publication date (yyyy-mm-dd)"
                        component={FormInput}
                        format={(value) => value.substring(0, value.indexOf('T'))}
                        parse={(value) => value + 'T00:00:00.000Z'}
                      />
                      <Field
                        name="abstract"
                        placeholder="Abstract"
                        numberOfLines={3}
                        component={FormInput}
                      />
                      <PickerField
                        name="status"
                        label="Publication status"
                        options={publicationStatusOptions}
                      />
                      <FormImageUploader
                        fieldName="featureImage"
                        label="Feature image"
                        width={formWidth}
                        query={BlogPostEditorPageDocument}
                        imageDataPath="blogPost.featureImage"
                        entityGUID={id}
                        usage="featureImage"
                      />
                    </FormCollapsibleSection>

                    <DynamicContentEditor
                      name="content"
                      width={formWidth}
                      ownerQuery={BlogPostEditorPageDocument}
                      ownerGUID={id}
                      ownerDataPath="blogPost"
                    />
                  </Content>
                </MediaDimensionsProvider>
              </Box>

              <Button alignSelf="flex-start" onPress={handleSubmit} disabled={!dirty}>
                <ButtonText>Save</ButtonText>
              </Button>
              <Prompt message="You have unsaved changes that will be lost" when={dirty} />
            </Box>
          )}
        </Form>
      </ImagePreviewProvider>
    </Page>
  )
}
