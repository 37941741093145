import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { OpportunitiesPage } from './pages/OpportunitiesPage/OpportunitiesPage'
import { OpportunityPage } from './pages/OpportunityPage/OpportunityPage'

export const Opportunities = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/:opportunityId`} component={OpportunityPage} />
      <Route exact path={`${match.url}`} component={OpportunitiesPage} />
      <Redirect to={`${match.url}`} />
    </Switch>
  )
}
