import { FC } from 'react'
import { Pressable } from 'react-native'
import { parseJSON } from 'date-fns'
import { Box, FormattedDate, H2, Image, Paragraph, Text } from '@common/components'
import { BlogPostListItemFragment } from '../../../../graphql'

export type Props = BlogPostListItemFragment & { onPress: () => void }

export const BlogPostListItem: FC<Props> = ({
  title,
  date,
  author,
  published,
  featureImage,
  onPress,
}) => {
  const width = 70
  return (
    <Pressable onPress={onPress}>
      <Box flexDirection="row" mb="smedium">
        <Image {...featureImage} width={width} aspectRatio={1} />
        <Box flex={1} ml="smedium">
          <H2 fontSize="medium" bold mb="none">
            {title}
          </H2>
          <Text fontSize="xsmall" color="dark4" mb="xxsmall">
            {author} <FormattedDate month="short" value={parseJSON(date)} />
          </Text>
          <Paragraph fontSize="xsmall" color="dark3">
            Published: {published ? 'Yes' : 'No'}
          </Paragraph>
        </Box>
      </Box>
    </Pressable>
  )
}
