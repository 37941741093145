import { useCallback, useState } from 'react'
import { useRestApi } from '@common/hooks'

export interface DropState {
  file: File
  headings: string
  records: string[]
}

interface Options {
  onCompleted: (n: null) => void
  onError: (error: Error | null) => void
}

interface State {
  isLoading: boolean
  isSuccess: boolean
}

export const useUploadCSV = ({
  onCompleted,
  onError,
}: Options): [(dropState: DropState) => void, State] => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const postLeads = useRestApi('POST', '/marketing-partners/ince', 'webhook')

  const submit = useCallback(
    async (dropState: DropState) => {
      onError(null)
      setIsLoading(true)
      const headings = dropState.headings.split(',')
      const leads = dropState.records.map((recordString) => {
        const record = recordString.split(',')
        return headings.reduce((lead, h, index) => {
          lead[h] = record[index]
          return lead
        }, {})
      })
      try {
        await postLeads(leads, {
          headers: {
            Authorization: 'bsY1XUl70X1yCWlRyDyPmZnvuw5lihk8ScbGiPUdrHsszk7FIsfQXMwULyAPsXAw',
          },
        })
        setIsSuccess(true)
        onCompleted(null)
      } catch (error) {
        onError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [postLeads, onCompleted, onError],
  )

  return [submit, { isLoading, isSuccess }]
}
