import { IntlProvider } from 'react-intl'
import { StyleSheet } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { BrowserRouter } from 'react-router-dom'
import SnackbarProvider from 'react-simple-snackbar'
import { ApolloProvider } from '@apollo/client'
import { FullScreenLoader } from '@common/components'
import { RestApiProvider } from '@common/hooks'
import { MediaThemeProvider } from '@common/media'
import { getTheme } from '@common/theme'
import { ErrorFallback } from './components/errors/UnhandledError'
import { ProvideAuth, useAuth } from './hooks/useAuth/useAuth'
import { useCachedResources } from './hooks/useCachedResources/useCachedResources'
import { Routes } from './modules/routes'
import { apolloClient } from './services/apolloClient'
import { BugsnagErrorBoundary } from './services/bugsnag'
import './services/firebase'
import { restUri } from './services/restApi/config'

const theme = getTheme('light')

function AppUI() {
  const resourcesLoading = useCachedResources()
  const { userLoading } = useAuth()

  return (
    <BugsnagErrorBoundary FallbackComponent={ErrorFallback}>
      <ProvideAuth>
        <RestApiProvider restUri={restUri}>
          <ApolloProvider client={apolloClient}>
            <SafeAreaProvider style={styles.fullScreen}>
              <IntlProvider messages={{}} locale="en-GB" defaultLocale="en">
                <MediaThemeProvider theme={theme}>
                  <SnackbarProvider>
                    {resourcesLoading || userLoading ? (
                      <FullScreenLoader />
                    ) : (
                      <BrowserRouter>
                        <Routes />
                      </BrowserRouter>
                    )}
                  </SnackbarProvider>
                </MediaThemeProvider>
              </IntlProvider>
            </SafeAreaProvider>
          </ApolloProvider>
        </RestApiProvider>
      </ProvideAuth>
    </BugsnagErrorBoundary>
  )
}

function App() {
  return (
    <ProvideAuth>
      <AppUI />
    </ProvideAuth>
  )
}

export default App

const styles = StyleSheet.create({
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})
