import Constants from 'expo-constants'
import Bugsnag from '@bugsnag/js'
import { configureApolloClient } from '@common/apollo-client'
import { getBearerTokenAsync } from '@common/hooks'

export const apolloClient = configureApolloClient({
  graphQLUri: Constants.manifest?.extra?.graphQLUri,
  getBearerTokenAsync,
  bugsnag: Bugsnag,
})
