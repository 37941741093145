import { useRouteMatch } from 'react-router-dom'
import { H1, Text } from '@common/components'
import { Page } from '../../../../components'
import { useOpportunityPageQuery } from '../../../../graphql'
import { UnitsTable } from './UnitsTable/UnitsTable'

export const OpportunityPage = () => {
  const {
    params: { opportunityId },
  } = useRouteMatch<{ opportunityId: string }>()

  const { loading, data } = useOpportunityPageQuery({
    variables: { id: opportunityId },
  })

  return (
    <Page>
      <H1>Opportunity: {data?.opportunity.title}</H1>
      {loading && <Text>Loading...</Text>}

      {data && (
        <>
          <UnitsTable
            units={data.opportunity.units}
            currency={data.opportunity.financials.currency.currency}
          />
        </>
      )}
    </Page>
  )
}
