import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Prompt, useRouteMatch } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import arrayMutators from 'final-form-arrays'
import omit from 'lodash/omit'
import { runFormMutation } from '@common/apollo-client'
import {
  Box,
  Button,
  ButtonText,
  FormInput,
  FullScreenLoader,
  PickerField,
  PickerInput,
} from '@common/components'
import { compileValidator } from '@common/final-form'
import { MediaDimensionsProvider } from '@common/media'
import { sentenceCase } from '@common/values'
import { Page } from '../../../../components'
import { FormCollapsibleSection } from '../../../../components/forms/FormCollapsibleSection/FormCollapsibleSection'
import { Content } from '../../../../components/mobile/Content/Content'
import {
  PushNotificationChannelId,
  SavePushCampaignMutation,
  SavePushCampaignMutationVariables,
  usePushCampaignEditorPageQuery,
  useSavePushCampaignMutation,
} from '../../../../graphql'
import { SendPushCampaignButtons } from './SendPushCampaignButtons'
import { DeepLinkTypeFormInput } from './deepLink/DeepLinkTypeFormInput'
import { DeepLinkUrlFormInput } from './deepLink/DeepLinkUrlFormInput'
import { PushCampaignPreviewAndroidField } from './previews/PushCampaignPreviewAndroid'
import { PushCampaignPreviewIOSField } from './previews/PushCampaignPreviewIOS'

const required = compileValidator({ $$root: true, type: 'string' })

const formWidth = 427
const previewWidth = 375

const segmentOptions = [{ label: 'All app users', value: 'allUsers' }]

const channelIdOptions = Object.entries(PushNotificationChannelId).map(([key, value]) => ({
  value,
  label: sentenceCase(key),
}))

export const PushCampaignEditorPage = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>()

  const [segment, setSegment] = useState(segmentOptions[0])

  const { loading, data } = usePushCampaignEditorPageQuery({ variables: { id } })

  const [openSnackbar] = useSnackbar()
  const [savePushCampaign] = useSavePushCampaignMutation({
    ignoreResults: true,
    onCompleted: (data) => openSnackbar(data.savePushCampaign.message, 2000),
  })

  if (loading || !data?.pushCampaign) {
    return <FullScreenLoader />
  }

  return (
    <Page>
      <Form<typeof data.pushCampaign>
        // @ts-ignore 3rd party library type mismatch
        mutators={arrayMutators}
        initialValues={data.pushCampaign}
        onSubmit={(values) =>
          runFormMutation<
            SavePushCampaignMutation,
            SavePushCampaignMutationVariables['input'],
            SavePushCampaignMutationVariables
          >(savePushCampaign, omit(values, ['sent']))
        }
        subscription={{ dirty: true }}
      >
        {({ handleSubmit, dirty, form }) => (
          <Box width={980} flexDirection="row" justifyContent="space-between" mx="auto">
            <Box width={previewWidth}>
              <Box borderWidth={1} borderColor="light4" borderRadius="medium" height={700}>
                <MediaDimensionsProvider width={previewWidth} height={700}>
                  <Content>
                    <PushCampaignPreviewAndroidField />
                    <Box height={30} />
                    <PushCampaignPreviewIOSField />
                  </Content>
                </MediaDimensionsProvider>
              </Box>
            </Box>

            <Box width={formWidth}>
              <MediaDimensionsProvider width={formWidth} height={700}>
                <FormCollapsibleSection title="Segment" collapsable={false}>
                  <PickerInput
                    options={segmentOptions}
                    selectedValue={segment}
                    // @ts-ignore Picker allows null selection and undefined option.value which isn't worth solving
                    onValueChange={setSegment}
                  />
                </FormCollapsibleSection>

                <FormCollapsibleSection title="Push notification" collapsable={false}>
                  <Field name="name" label="Campaign name" component={FormInput} />
                  <PickerField
                    name="channelId"
                    label="Channel ID (Android)"
                    options={channelIdOptions}
                  />
                  <Field name="title" label="Title" component={FormInput} validate={required} />
                  <Field name="subTitle" label="Sub title (iOS)" component={FormInput} />
                  <Field
                    name="body"
                    label="Body"
                    component={FormInput}
                    numberOfLines={5}
                    validate={required}
                  />
                  <Field
                    name="deepLinkType"
                    label="Deep link type"
                    component={DeepLinkTypeFormInput}
                  />
                  <Field name="deepLinkUrl" component={DeepLinkUrlFormInput} validate={required} />
                </FormCollapsibleSection>

                <Box flexDirection="row" justifyContent="flex-end" mb="smedium">
                  <Button onPress={() => form.reset()} disabled={!dirty} variant="link">
                    <ButtonText>Revert</ButtonText>
                  </Button>
                  <Button onPress={handleSubmit} disabled={!dirty} ml="smedium">
                    <ButtonText>Save</ButtonText>
                  </Button>
                </Box>
                <Box flexDirection="row" justifyContent="flex-end">
                  <SendPushCampaignButtons id={id} segment={segment?.value} dirty={dirty} />
                </Box>
              </MediaDimensionsProvider>
            </Box>

            <Prompt message="You have unsaved changes that will be lost" when={dirty} />
          </Box>
        )}
      </Form>
    </Page>
  )
}
