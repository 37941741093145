import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth/useAuth'
import { Unauthorized } from './auth/Unauthorized'

export const AdminRoute = (props: RouteProps) => {
  const { user, roles } = useAuth()
  const location = useLocation()
  if (!user) {
    return <Redirect to={{ pathname: '/auth/sign-in', state: { location } }} />
  }
  return roles.includes('admin') ? <Route {...props} /> : <Unauthorized />
}

export const PublicOnlyRoute = (props: RouteProps) => {
  const { user } = useAuth()
  return user ? <Redirect to={{ pathname: '/' }} /> : <Route {...props} />
}
