import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { InvestorsPage } from './pages/InvestorsPage/InvestorsPage'

export const Investors = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match.url}/`} component={InvestorsPage} />
      <Redirect to={`${match.url}/`} />
    </Switch>
  )
}
