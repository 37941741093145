import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { InceLeadsPage } from './pages/InceLeadsPage'

export const CsvUpload = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.url}/ince`}>
        <InceLeadsPage />
      </Route>
    </Switch>
  )
}
