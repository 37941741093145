import { StyleSheet, View } from 'react-native'
import { H3, Paragraph } from '@common/components'

export function UnhandledErrorScreen() {
  return (
    <View style={styles.container}>
      <H3>There was an unexpected error.</H3>
      <Paragraph textAlign="center">
        We have logged the error and will investigate the issue. We do apologise for the
        inconvenience.
      </Paragraph>
      <Paragraph>Please close and restart the app to continue.</Paragraph>
    </View>
  )
}

export const ErrorFallback =
  process.env.NODE_ENV === 'production' ? UnhandledErrorScreen : undefined

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
})
