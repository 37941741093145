import { useFormState } from 'react-final-form'
import { Text, View } from 'react-native'
import styled from 'styled-components'
import { Image } from '@common/components'
import { PushCampaign, PushCampaignPreviewFragment } from '../../../../../graphql'
import icon from './notification-icon.png'

const Container = styled(View)({
  borderWidth: 1,
  borderColor: '#bbb',
  borderRadius: 8,
  padding: 12,
  marginLeft: -12,
  marginRight: -12,
})

const MessageHead = styled(View)({
  flexDirection: 'row',
  marginBottom: 10,
})

const Icon = styled(Image)({
  width: 16,
  height: 16,
  marginRight: 8,
})

const Sender = styled(Text)({
  fontSize: 11,
  color: '#666',
})

const Title = styled(Text)({
  color: '#000',
  fontSize: 13,
  fontWeight: 600,
  marginBottom: 3,
})

const Body = styled(Text)({
  color: '#000',
  fontSize: 13,
})

type Props = { campaign: PushCampaignPreviewFragment }

export const PushCampaignPreviewAndroid = ({ campaign }: Props) => {
  return (
    <Container>
      <MessageHead>
        <Icon source={icon} width={16} height={16} />
        <Sender>Property Clan&nbsp;&nbsp;•&nbsp;&nbsp;7m</Sender>
      </MessageHead>
      <Title>{campaign.title}</Title>
      <Body>{campaign.body}</Body>
    </Container>
  )
}

export const PushCampaignPreviewAndroidField = () => {
  const { values } = useFormState<PushCampaign>({ subscription: { values: true } })
  return <PushCampaignPreviewAndroid campaign={values} />
}
